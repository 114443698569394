import React, {useContext} from "react";
import Agent from "../../common/communication/agent";
import {
  CSV_EXAMPLE_DATA,
} from "../../common/constants/dev_artifacts";
import {saveAs} from 'file-saver';
import {
  ATTRIBUTE_CERTIFICATE_BEGIN_HEADER,
  ATTRIBUTE_CERTIFICATE_END_HEADER,
  CERTIFICATE_ZIP_FILE_NAME
} from "../../common/constants/string_defaults";
import {AlertDataContext, handleError, setErrorAlert, setSuccessAlert} from "../../common/components/alerter";
import CsvPickerComponent from "./components/csv_picker";
import {CSV_EXAMPLE_DOWNLOAD_ERROR, ISSUE_SUCCESS} from "../../common/constants/errors";
import TemplateGrid from "../../template/list/components/template_grid";
import {YbyraContainer} from "../../common/style";

const IssueCertificateByCsvComponent = () => {

  const [csvFileText, setCsvFileText] = React.useState("");
  const [templateId, setTemplateId] = React.useState(-1);
  const [ykueId, setYkueId] = React.useState("");
  const [issued_certificates, setIssuedCertificates] = React.useState([]);
  const alertContext = useContext(AlertDataContext);

  const issueCertificates = async () => {
    Agent.Certificate.issue_by_csv({
      "templateId": templateId, "csvFileText": csvFileText, "ykueId": ykueId
    })
      .then(response => {
        setIssuedCertificates(response);
        setSuccessAlert(ISSUE_SUCCESS, alertContext);
      }).catch(error => {
        handleError(error, alertContext)
      })

  }

  const handleDownloadExample = async (e) => {
    e.preventDefault();
    const {header, rows} = CSV_EXAMPLE_DATA();
    await Agent.Template.getById(templateId)
      .then(template => {
        template.templateParameters.attributesSet.map(attribute => {
          if (attribute.modifiable) {
            header.push(attribute.oid);
            rows.map((row, i) => {
              rows[i] = row += ("," + attribute.value)
            });
          }
          const csvContent = `${header.join(",")}\n${rows.join('\n')}`;
          let csvFile = new File([csvContent], template.name.replaceAll(" ", "_") + "_example.csv", {type: 'text/csv'});
          saveAs(csvFile);
        })
      }).catch(() => {
        setErrorAlert(CSV_EXAMPLE_DOWNLOAD_ERROR, alertContext);
      })
  }

  const renderExampleDownloadCSV = () => {
    return (
      <div>
        <div
          className="d-flex align-items-center"
        >
          <button
            className="btn btn-primary"
            onClick={(e) => {handleDownloadExample(e)}}
            disabled={templateId === -1}
          >
              Download example CSV
          </button>
          <div style={{paddingLeft: "10px"}}>
            <small className="text-muted align-items-center">
              {(templateId === -1) ? "" : "Select a template to download a example CSV"}
            </small>
          </div>
        </div>
      </div>
    );
  }

  const canIssue = (form) => {
    return form.template !== null && csvFileText !== null && csvFileText.trim() !== "" &&
        ykueId != null && ykueId.trim() !== "";
  }

  const renderButtons = () => {
    return (
      <div
        className="d-flex justify-content-between"
      >
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => issueCertificates()}
          disabled={!canIssue({templateId, csvFileText, ykueId})}
        >Issue
        </button>
        {renderDownloadButton()}
      </div>
    );
  }

  const downloadZip = async (e, files) => {
    e.preventDefault();
    let zip = require('jszip')();
    files.forEach((file) => {
      zip.file(file.name, file);
    })
    await zip.generateAsync({type: "blob"}).then(content => {
      saveAs(content, CERTIFICATE_ZIP_FILE_NAME);
    });
  }

  const renderDownloadButton = () => {
    if (!issued_certificates) return;
    let rows = [];
    let certificateFiles = [];
    let correctCertificatesCounter = 0

    issued_certificates.map((issued_certificate, certIndex) => {
      if (issued_certificate.success) {
        let fileContent =
            ATTRIBUTE_CERTIFICATE_BEGIN_HEADER +
            issued_certificate.pem +
            ATTRIBUTE_CERTIFICATE_END_HEADER
        let certificateFile = new File(
          [fileContent],
          "certificate" + (certIndex + 1) + ".pem",
          {type: 'application/pkix-cert'});
        certificateFiles.push(certificateFile);
        correctCertificatesCounter++;
      }
    })
    rows.unshift(
      <div>
        <button
          className="btn btn-primary"
          type="submit"
          onClick={(e) => {
            downloadZip(e, certificateFiles)
          }}
          disabled={correctCertificatesCounter === 0}
        >
            Download Zip
        </button>
      </div>
    );

    return rows;
  }

  return (
    <YbyraContainer>
      <h2>Issue Certificates from CSV file</h2>
      <form>
        <div>
          <TemplateGrid isEditable={false} setParentTemplateId={setTemplateId} />
          <p></p>
          <div>{renderExampleDownloadCSV()}</div>
          <p></p>
          <div>
            <CsvPickerComponent onChange={setCsvFileText}>
            </CsvPickerComponent>
            <p></p>
          </div>
          <p></p>
          <p></p>
          <label htmlFor="issue-certificate-dn" className="form-label">
              Ykue Identifier:{" "}
          </label>
          <input
            className="form-control"
            type="text"
            id="ykueId"
            name="ykueId"
            onChange={(e) => setYkueId(e.target.value)}
            value={ykueId}
          />
          <p></p>
        </div>
        {renderButtons()}
      </form>
    </YbyraContainer>
  );
}

export default IssueCertificateByCsvComponent;
