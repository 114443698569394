import React from 'react';
import {useState} from "react";
import TemplateHeader from "./components/template_header";
import {YbyraContainer} from "../../common/style";
import CreateTemplateButton from "./components/create_template_button";
import Agent from '../../common/communication/agent.js'
import {AttributeList} from "../list/components/attribute_list";
import {env} from "../../../env";


const CreateTemplateLoaderComponent = () => {
  const initialAIA = {value : `${env.REACT_APP_AIA_BASE_URI}`, type : "CA"};
  const [templateName, setTemplateName] = useState("");
  const [validity, setValidity] = useState("");
  const [attributes, setAttributes] = useState([]);
  const [auditIdentity, setAuditIdentity] = useState(false);
  const [authorityKeyIdentifier, setAuthorityKeyIdentifier] = useState(false);
  const [aCTargetsSet, setACTargetsSet] = useState([]);
  const [accessDescriptionsSet, setAccessDescriptionsSet] = useState([]);
  const [publicTemplate, setPublicTemplate] = useState(false);
  const [aiaBaseUri, setAiaBaseUri] = useState(initialAIA);
  const [revocation, setRevocation] = useState(true);


  function clearFields() {
    setTemplateName("");
    setPublicTemplate(false)
    setValidity("");
    setAttributes([])
    setAuthorityKeyIdentifier(false)
    setAuditIdentity(false)
    setRevocation(false)
    setACTargetsSet([])
    setAiaBaseUri(initialAIA)
    setAccessDescriptionsSet([])
  }

  function setAia(aia){
    setAiaBaseUri({value: aia, type: "CA"});
  }

  function toggleRevocable(){
    setRevocation(!revocation)
  }

  function togglePublicTemplate(){
    setPublicTemplate(!publicTemplate)
  }

  return (
    <YbyraContainer>
      <h2>Create Template</h2>
      <form>
        <TemplateHeader
          templateName={templateName.valueOf()}
          onChangeTemplateName={setTemplateName}
          acessDescription={accessDescriptionsSet}
          setAcessDescription={setAccessDescriptionsSet}
          validity={validity}
          setValidity={setValidity}
          revocation={revocation}
          toggleRevocable={toggleRevocable}
          aia={aiaBaseUri.value}
          setAia={setAia}
          publicTemplate={publicTemplate}
          changePublicTemplate={togglePublicTemplate}
          attributes={attributes}
          setAttributes={setAttributes}
        />
        <div style={{marginTop:"20px"}}>
          <AttributeList rows={attributes} setRows={setAttributes}/>
        </div>
        <div style={{display: 'flex', justifyContent:'flex-end', marginTop: '1rem',marginBottom: '0.5rem'}}>
          <CreateTemplateButton
            agent={Agent}
            aia={aiaBaseUri.value}
            attributes={attributes}
            publicTemplate={publicTemplate}
            noRevAvail={revocation}
            templateName={templateName}
            validity={validity}
            authorityKeyID={authorityKeyIdentifier}
            auditIdentity={auditIdentity}
            acTargetSet={aCTargetsSet}
            clearFields={clearFields}
          />
        </div>
      </form>
    </YbyraContainer>
  );
}
export default CreateTemplateLoaderComponent;
