import {Route} from "react-router-dom";
import React from "react";
import IssueCertificateComponentByCsv from "./issue_csv";

const issueCSVRoutes = (
  <React.Fragment>
    <Route path="issue/csv" element={<IssueCertificateComponentByCsv />} />
  </React.Fragment>
)

export default issueCSVRoutes;
