import React, {useContext} from "react";
import Agent from "../../common/communication/agent";
import {AlertDataContext, handleError} from "../../common/components/alerter";
import {verifyCpfCnpj} from "../../common/functions/cpf_cnpj";
import {YbyraContainer} from "../../common/style";

const SystemConfigurationComponent = () => {

  const [cpfCnpj, setCpfCnpj] = React.useState("");
  const alertContext = useContext(AlertDataContext);

  const configureSystem = () => {
    Agent.Server.config({cpfCnpj : cpfCnpj}
    ).then(() => {
      window.location.reload();
    }).catch((error) => {
      handleError(error, alertContext)
    });
  }

  return (
    <YbyraContainer>
      <h2>System Configuration</h2>

      <form id="system-configuration">
        <p>System initialization requires the registration of the first administrator.</p>
        <div className="mb-3">
          <label htmlFor="cpfCnpj" className="form-label">Administrator&apos;s CPF or CNPJ</label>
          <input className="form-control" type="text" id="cpfCnpj" name="cpfCnpj"
            onChange={(e) => {
              setCpfCnpj(e.target.value)
            }}/>
        </div>
        <button type="button" className="btn btn-primary" onClick={() => configureSystem()}
          disabled={!verifyCpfCnpj(cpfCnpj)}
        >Submit
        </button>
      </form>
    </YbyraContainer>
  );

}

export default SystemConfigurationComponent;
