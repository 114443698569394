import {Route} from "react-router-dom";
import React from "react";
import CreateTemplateComponent from "./create_template";

const createTemplateRoutes = (
  <React.Fragment>
    <Route path="templates/create" element={<CreateTemplateComponent />} />
  </React.Fragment>
)

export default createTemplateRoutes;
