import {Route} from "react-router-dom";
import React from "react";
import TemplateComponent from "./template";

const templateRoutes = (
  <React.Fragment>
    <Route path="template/*" element={<TemplateComponent />} />
  </React.Fragment>
)

export default templateRoutes;