export const REVOCATION_ERROR = "The following certificates could not be revoked: ";
export const REVOCATION_ERROR_GENERAL = "Could not revoke any certificate.";
export const REVOCATION_WARNING = "Some certificates certificates were not successfully revoked."
export const CERT_LIST_ERROR = "Could not load any certificates. Please check your connection.";
export const USER_LIST_ERROR = "Could not load any users with the given filter and page. " +
                                        "Please check your connection or add new users.";
export const USER_CHANGE_STATUS_ERROR = "Could not update the status of this user.";
export const TEMPLATE_LIST_ERROR = "Could not load any templates. Please check your connection or add new users.";
export const TEMPLATE_UPDATE_ERROR = "Could not update template. Reason: ";
export const CSV_EXAMPLE_DOWNLOAD_ERROR = "Failed to download example CSV for specified template.";
export const RETRIEVE_MODIFIABLE_ATTRIBUTES_ERROR = "Failed to retrieve modifiable attributes for specified template.";
export const UNKNOWN_ERROR = "An unknown error has ocurred"

export const ISSUE_SUCCESS = "Certificate(s) issued successfully.";
export const AUTH_SUCCESS = "Login successful.";
export const CHANGE_ROLE_SUCCESS = "Role successfully changed.";
export const REVOCATION_SUCCESS = "Certificate(s) revoked successfully.";
export const TEMPLATE_SUCCESS =  "Template created successfully.";
export const USER_SUCCESS =  "User registered successfully.";
