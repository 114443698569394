import {
  SET_TEMPLATE_LIST
} from '../features/common/components/certificate_list/action_type';

const defaultState = {
  templateList: []
};

const TemplateList = (state = defaultState, action) => {
  switch (action.type) {
  case SET_TEMPLATE_LIST:
    return {...state, templateList: action.value};
  default:
    return state;
  }

};

export default TemplateList;
