import {useState} from "react";
import React from 'react';
import PropTypes from "prop-types";

export const CertificateFilter = (
  {validFilter, setValidFilter, commonNameFilter, setCommonNameFilter, serialNumberFilter, setSerialNumberFilter}
) => {
  const [localCommonNameFilter, setLocalCommonNameFilter] = useState(commonNameFilter);
  const [localSerialNumberFilter, setLocalSerialNumberFilter] = useState(serialNumberFilter);
  const [localValidFilter, setLocalValidFilter] = useState(validFilter);

  /**
	 * The states of this and the parent component are kept separate to avoid sending multiple requests to the server
	 */
  function updateValues() {
    setValidFilter(localValidFilter);
    setCommonNameFilter(localCommonNameFilter);
    setSerialNumberFilter(localSerialNumberFilter);
  }

  return (
    <div style={{"padding":"25px"}}>
      <h5>Filters</h5>
      <div className="d-flex justify-content-between">
        <SerialNumberFilter setLocalSerialNumberFilter={setLocalSerialNumberFilter}/>
        <CommonNameFilter setLocalCommonNameFilter={setLocalCommonNameFilter}/>
        <ValidCertificateFilter localValidFilter={localValidFilter} setLocalValidFilter={setLocalValidFilter}/>
        <ApplyFilterButton updateValues={updateValues}></ApplyFilterButton>
      </div>
    </div>
  )
}

CertificateFilter.propTypes = {
  validFilter: PropTypes.bool.isRequired,
  setValidFilter: PropTypes.func.isRequired,
  commonNameFilter: PropTypes.string.isRequired,
  setCommonNameFilter: PropTypes.func.isRequired,
  serialNumberFilter: PropTypes.string.isRequired,
  setSerialNumberFilter: PropTypes.func.isRequired
}


const SerialNumberFilter = ({setLocalSerialNumberFilter}) => {
  return (
    <div>
      <span>Serial Number</span>
      <input className="form-control" onChange={(event) => setLocalSerialNumberFilter(event.target.value)}
      />
    </div>
  );
}

SerialNumberFilter.propTypes = {
  setLocalSerialNumberFilter: PropTypes.func.isRequired
}

const CommonNameFilter = ({setLocalCommonNameFilter}) => {
  return (
    <div>
      <span>Common Name</span>
      <input className="form-control" onChange={(event) => setLocalCommonNameFilter(event.target.value)}
      />
    </div>
  );
}

CommonNameFilter.propTypes = {
  setLocalCommonNameFilter: PropTypes.func.isRequired
}

const ValidCertificateFilter = ({localValidFilter, setLocalValidFilter}) => {
  return (
    <div>
      <br/>
      <div className="form-check">
        <input className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          onClick={() => setLocalValidFilter(!localValidFilter)}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
					Valid Certificates Only
        </label>
      </div>
    </div>
  )
}

ValidCertificateFilter.propTypes = {
  localValidFilter: PropTypes.string.isRequired,
  setLocalValidFilter: PropTypes.func.isRequired
}

const ApplyFilterButton = ({updateValues}) => {
  return (
    <div>
      <br/>
      <button className="btn btn-primary"
        onClick={updateValues}
        data-toggle="button"
      >Apply Filter</button>
    </div>
  )
}

ApplyFilterButton.propTypes = {
  updateValues: PropTypes.func.isRequired,
}