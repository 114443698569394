import {Route} from "react-router-dom";
import PageNotFound from "./page_not_found";
import React from "react";

const notFoundRoutes = (
  <React.Fragment>
    <Route path="*" element={<PageNotFound/>} />
  </React.Fragment>
)

export default notFoundRoutes;