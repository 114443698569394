import React, {useContext, useEffect} from 'react';
import {
  DataGrid,
  getGridStringOperators,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport, GridToolbarFilterButton
} from '@mui/x-data-grid';
import Agent from "../../common/communication/agent";
import {AlertDataContext, setErrorAlert} from "../../common/components/alerter";
import {USER_CHANGE_STATUS_ERROR, USER_LIST_ERROR} from "../../common/constants/errors";
import {Switch} from "@mui/material";
import {YbyraContainer} from "../../common/style";

const UserGrid = () => {

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });


  const alertContext = useContext(AlertDataContext);

  const [data, setData] = React.useState([]);

  const [rowCountState, setRowCountState] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState([5, 10, 20, 50, 100]);

  const [filter, setFilter] = React.useState([]);


  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['equals'].includes(value),
  );

  const renderActiveButton = (cellValues) => {
    return (
      <Switch
        checked={cellValues.row.active}
        onChange={(event) => {
          changeUserStatus(event, cellValues);
        }}
        inputProps={{ 'aria-label': 'controlled' }}
      />

    );
  }

  const columns = [
    { field: 'id', headerName: "Id", flex: 0.5, filterOperators },
    { field: 'name', headerName: "User Name", flex: 1, filterOperators},
    { field: 'cpfCnpj', headerName: "CPF/CNPJ", flex: 1, filterOperators},
    { field: 'email', headerName: "E-mail", flex: 1, filterOperators},
    { field: 'active', headerName: "Is user active?", flex: 1, filterOperators, renderCell: renderActiveButton }
  ];



  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const generatePageSizeOptions = (totalRows) => {
    const pageCount = Math.ceil(totalRows / 5);
    return Array.from({ length: pageCount }, (_, index) => (index + 1) * 5)
      .filter(option => option <= 15);
  }



  const doRequest = async () => {
    const page = {};
    page.page = paginationModel.page;
    page.size = paginationModel.pageSize;
    const params = Object.assign({}, page, filter);
    try {
      const response = await Agent.User.list(params);
      setData(response.content);
      setRowCountState(response.totalElements);
      const pageSizeOp = generatePageSizeOptions(response.totalElements);
      setRowsPerPage(pageSizeOp);
    } catch (error) {
      setErrorAlert(USER_LIST_ERROR, alertContext);
    }

  }

  const changeUserStatus = async (event, cellValues) => {

    try {
      await Agent.User.update(cellValues.row.id, !cellValues.row.active);
    } catch (error) {
      setErrorAlert(USER_CHANGE_STATUS_ERROR, alertContext);
    }

    setData((prevRows) => {
      return prevRows.map((row) =>
        row.id === cellValues.row.id ? { ...row, active: !cellValues.row.active } : row,
      );
    });

  }

  const onFilterChange = React.useCallback((filterModel) => {
    var filter = {};
    filterModel.items.forEach( f => {
      filter[f.field] = f.value;
      setFilter(filter);
    });
    setFilter(filter);
  }, []);


  useEffect(() => { doRequest(); }, [paginationModel.page, paginationModel.pageSize, filter]);


  return (
    <YbyraContainer>
      <h2>Manage Users</h2>

      <DataGrid keepNonExistentRowsSelected
        rows={data}
        slots={{toolbar: CustomToolbar}}
        rowCount={rowCountState}
        loading={false}
        pageSizeOptions={rowsPerPage}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        columns={columns}
      />
    </YbyraContainer>
  );

}

export default UserGrid;
