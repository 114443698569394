import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from "prop-types";
import { RoleEnum, ServerStatusEnum } from "../../common/constants/enums";
import { useNavigate } from "react-router-dom";
import { createContext, Fragment, useContext } from "react";
import Box from '@mui/material/Box';
import {Person} from "@material-ui/icons";

export const ResponsiveAppBar = ({ currentUser}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuId, setMenuId] = React.useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuId(null);
  };

  return (
    <>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'white', color: 'black' }}>
        <Container disableGutters>
          <Toolbar disableGutters>
            <YbyraLogo />
            <Box sx={{ flexGrow: 1 }} />
            <NavBarButtons
              currentUser={currentUser}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              menuId={menuId}
              setMenuId={setMenuId}
              handleMenuClose={handleMenuClose}
            />
          </Toolbar>
        </Container>
        <Box sx={{ width: '100%', height: '1px', bgcolor: 'lightGray' }} /> {/* Separator Line */}
      </AppBar>
      <Toolbar />
    </>
  );
};

const AuthenticationButton = ({ currentUser, anchorEl, setAnchorEl, menuId, setMenuId, handleMenuClose }) => {
  const navigate = useNavigate();
  const context = useContext(AppBarDataContext);

  const createButton = () => {
    switch (currentUser) {
    case RoleEnum.NONE:
      return makeLogin();
    default:
      return makeUser();
    }
  }

  const handleChangeRole = () => {
    handleMenuClose();
    navigate('change-role');
  }
  const handleLogoutClick = (event)  => {
    event.preventDefault();
    event.stopPropagation();
    context.setToken(null);
    context.setRefreshToken(null);
    window.localStorage.removeItem("role");
    handleMenuClose();
    navigate('/');
    window.location.reload();
  }

  const makeUser = () => {
    return (<Fragment>
      <Button onClick={(event) => {
        setAnchorEl(event.currentTarget);
        setMenuId('userMenu');
      }}>
        <Person/> {currentUser}
      </Button>
      <Menu
        open={menuId === 'userMenu'}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleMenuClose}
      >
        <MenuItem to='change-role' onClick={handleChangeRole}>Change Role</MenuItem>
        <MenuItem to="/" onClick={handleLogoutClick}>Logout</MenuItem>
      </Menu>
    </Fragment>
    );
  }

  const makeLogin = () => {
    return (
      <Button
        onClick={() => {
          navigate('login');
        }}
        sx={{
          my: 0,
          backgroundColor: 'green',
          color: 'white',
          marginLeft: "auto",
          borderRadius: 28,
          '&:hover': {
            backgroundColor: 'green', // Maintain the same background color on hover
          },
        }}
      >
        <Person/> LOGIN WITH YKUE
      </Button>)
  }

  return (
    <Fragment>
      {createButton()}
    </Fragment>
  );
}

const NavBarButtons = ({ currentUser, anchorEl, setAnchorEl, menuId, setMenuId, handleMenuClose }) => {
  const navigate = useNavigate();
  let buttonList;

  switch (currentUser) {
  case RoleEnum.ADMINISTRATOR:
    buttonList = [
      {
        name: 'User',
        route: [
          { name: "Create", route: "users/create" },
          { name: "Manage", route: "users/manage" }
        ]
      },
    ];
    break;
  case RoleEnum.OPERATOR:
    buttonList = [
      {
        name: 'Template',
        route: [
          { name: "Create", route: "templates/create" },
          { name: "Manage", route: "templates/manage" }
        ]
      },
      {
        name: 'Issue',
        route: [
          { name: "CSV", route: "issue/csv" },
          { name: "DN", route: "issue/dn" }
        ]
      },
      { name: 'Certificates',
        route: [
          { name: "List", route: "certificates/list" },
          { name: "Revoke", route: "certificates/revoke" }
        ]
      },
    ];
    break;
  default:
    buttonList = [];
  }

  const handleMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMenuId(id);
  };

  const renderSimpleButton = (page) => (
    <Button
      key={page.name}
      onClick={() => {
        handleMenuClose();
        navigate(page.route);
      }}
      sx={{ my: 0, color: 'green', display: 'block' }}
    >
      {page.name}
    </Button>
  );

  const renderMenuItems = (routes) => {
    return routes.map((route) => (
      <MenuItem
        key={route.name}
        onClick={() => {
          handleMenuClose();
          navigate(route.route);
        }}
      >
        {route.name}
      </MenuItem>
    ));
  };

  const makeButtons = () => {
    return buttonList.map((page) => (
      <div key={page.name}>
        {Array.isArray(page.route) ? (
          <div>
            <Button
              onClick={(event) => handleMenu(event, page.name)}
              sx={{ my: 0, color: 'green', display: 'block' }}
            >
              {page.name}
            </Button>
            <Menu
              open={menuId === page.name}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleMenuClose}
            >
              {renderMenuItems(page.route)}
            </Menu>
          </div>
        ) : (
          renderSimpleButton(page)
        )}
      </div>
    ));
  };

  return (
    <Fragment>
      {makeButtons()}
      <AuthenticationButton
        currentUser={currentUser}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        menuId={menuId}
        setMenuId={setMenuId}
        handleMenuClose={handleMenuClose}
      />
    </Fragment>
  );
};

const YbyraLogo = () => {
  return (
    <Fragment>
      <Typography
        variant="h6"
        noWrap
        component="a"
        style={{ textDecoration: "none" }}
        href=""
        sx={{
          mr: 2,
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '0rem',
          color: 'green',
          textDecoration: 'none',
        }}
      >
        <img
          src={require('../../../styles/labsec-digital.svg').default}
          alt='mySvgImage'
          style={{ width: 'auto', height: '1.5em', verticalAlign: 'middle' }} // Adjust the height as needed
        />
        YBYRÁ
      </Typography>

      <Typography
        variant="subtitle1"
        noWrap
        component="span"
        style={{ textDecoration: "none" }}
        sx={{
          display: { xs: 'none', md: 'flex' },
          fontFamily: 'monospace',
          fontWeight: 400,
          letterSpacing: '0rem',
          color: 'gray',
          ml: 1,
          fontSize: '0.8rem', // Adjust the font size here
        }}
      >
          Attribute Certificate <br /> Manager
      </Typography>
    </Fragment>
  );
};

ResponsiveAppBar.propTypes = {
  serverStatus: PropTypes.oneOf(Object.keys(ServerStatusEnum)).isRequired,
  currentUser: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  devMode: PropTypes.bool.isRequired,
  developerMode: PropTypes.bool.isRequired,
  onToggleDeveloperMode: PropTypes.func.isRequired,
  onDevModeChange: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  setMenuId: PropTypes.func.isRequired,
  menuId: PropTypes.string.isRequired,
};

NavBarButtons.propTypes = {
  currentUser: PropTypes.string.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  setMenuId: PropTypes.func.isRequired,
  menuId: PropTypes.string.isRequired,
  anchorEl: PropTypes.string.isRequired,
  setAnchorEl: PropTypes.string.isRequired
}

AuthenticationButton.propTypes = {
  currentUser: PropTypes.string.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  setMenuId: PropTypes.func.isRequired,
  menuId: PropTypes.string.isRequired,
  anchorEl: PropTypes.string.isRequired,
  setAnchorEl: PropTypes.string.isRequired
}

export const AppBarDataContext = createContext({
  token: "", setToken: () => { },
  refreshToken: "", setRefreshToken: () => { },
  currentRole: "", setCurrentRole: () => { },
});
