import {
  ADD_TEMPLATE_AUTH_FIELD,
  ADD_TEMPLATE_AUTH_VALUE,
  SET_TEMPLATE_AUTH_MESSAGE,
  SET_TEMPLATE_AUTH_OID,
  SET_TEMPLATE_AUTH_VALUE
} from '../features/common/components/certificate_list/action_type';

const defaultState = {
  fields: [],
  message: ""
};

const TemplateAuth = (state = defaultState, action) => {
  switch (action.type) {
  case ADD_TEMPLATE_AUTH_FIELD:
    return {...state, fields: [...state.fields, {oid: "", values: []}]};
  case ADD_TEMPLATE_AUTH_VALUE: {
    let newFields = [...state.fields];
    newFields[action.index].values.push("");
    return {...state, fields: newFields};
  }
  case SET_TEMPLATE_AUTH_VALUE: {
    let newFields = [...state.fields];
    newFields[action.field_index].values[action.value_index] = action.value;
    return {...state, fields: newFields};
  }
  case SET_TEMPLATE_AUTH_OID: {
    let newFields = [...state.fields];
    newFields[action.index].oid = action.value;
    return {...state, fields: newFields};
  }
  case SET_TEMPLATE_AUTH_MESSAGE: {
    return {...state, message: action.value};
  }
  default:
    return state;
  }

};

export default TemplateAuth;
