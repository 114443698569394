import React, {Fragment, useContext, useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { RoleEnum, ServerStatusEnum } from "./features/common/constants/enums";
import { APP_LOAD, LOGIN, REDIRECT } from "./features/common/components/certificate_list/action_type";
import { store } from "./store";
import { push } from "connected-react-router";
import Agent from "./features/common/communication/agent";
import {connect} from "react-redux";
import { useStyles } from "./styles/main_styles";
import jwt from "jsonwebtoken";
import {Routes, useNavigate} from "react-router-dom";
import {AlertDataContext} from "./features/common/components/alerter";
import PropTypes from "prop-types";
import {AppBarDataContext} from "./features/navbar/components/appbar";
import {navbarAdmRoutes, navbarRoutes} from "./features/navbar/components/route";
import notFoundRoutes from "./features/not_found/route";
import {ServerStatusContext} from "./features/common/components/server_status";
import {YbyraBackground} from "./features/common/style";

const mapStateToProps = (state) => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
    serverStatus: state.common.serverStatus,
    developerMode: state.developerMode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload, token, refreshToken) =>
    dispatch({ type: APP_LOAD, payload, token, refreshToken, skipTracking: true }),
  onRedirect: () => dispatch({ type: REDIRECT }),
  onLogin: (payload) => dispatch({ type: LOGIN, payload }),
});

const App = (props) => {
  const classes = useStyles();
  return <YbyraBackground>
    <AllowanceApp classes={classes} {...props} />
  </YbyraBackground>;
};

class AllowanceApp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      serverStatus: ServerStatusEnum.OFFLINE
    };
  }

  // eslint-disable-next-line no-unused-vars
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.redirectTo) {
      store.dispatch(push(nextProps.redirectTo));
      this.props.onRedirect();
    }
  }

  UNSAFE_componentWillMount() {
    Agent.Server.status().then((response) => {
      const token = window.localStorage.getItem("jwt");
      const refreshToken = window.localStorage.getItem("refreshToken");
      let role = window.localStorage.getItem("role");
      const payload = {
        token: token || null,
        refreshToken: refreshToken || null,
        serverStatus: response.status,
      };
      this.setState({serverStatus: response.status})
      if (token && role) {
        if (jwt.decode(token).exp < Date.now() / 1000) {
          localStorage.clear();
        } else {
          role = role === undefined ? RoleEnum.NONE : role;

          this.props.onLogin({
            token: token,
            refreshToken: refreshToken,
            role: role,
          });
          Agent.setToken(token, refreshToken);
        }
      }
      this.props.onLoad(payload, token, refreshToken);
    });
  }

  render() {
    return (
      <Fragment className={this.props.classes.paper}>
        <ServerStatusContext.Provider value={this.state.serverStatus}>
          <MainPage
            currentUser={this.props.currentUser}
            serverStatus={this.props.serverStatus}
            appName={this.props.appName}
            appLoaded={this.props.appLoaded}
          >
          </MainPage>
        </ServerStatusContext.Provider>
      </Fragment>
    );
  }
}

AllowanceApp.propTypes = {
  currentUser: PropTypes.oneOf(Object.keys(RoleEnum)).isRequired,
  serverStatus: PropTypes.oneOf(Object.keys(ServerStatusEnum)).isRequired,
  appName: PropTypes.string.isRequired,
  appLoaded: PropTypes.bool.isRequired,
  classes: PropTypes.any.isRequired,
  onLoad: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  redirectTo: PropTypes.any.isRequired,
  onRedirect: PropTypes.func.isRequired
}

const MainPage = ({currentUser, serverStatus, appName, appLoaded}) => {
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [token, setToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (serverStatus === ServerStatusEnum.UNCONFIGURED) {
      navigate('/adm');
    }
  }, [serverStatus, navigate]);


  const renderPage = () => {
    if (appLoaded && serverStatus === ServerStatusEnum.UNLOCKED) {
      return (
        <UserPage currentUser={currentUser}
          serverStatus={serverStatus}
          appName={appName}
        />
      )
    } else {
      return (
        <AdmPage currentUser={currentUser}
          serverStatus={serverStatus}
          appName={appName}>
        </AdmPage>
      );
    }
  }

  return (
    <AppBarDataContext.Provider value={{
      token, setToken,
      refreshToken, setRefreshToken,
    }}>
      <AlertDataContext.Provider value={{
        isAlert, setIsAlert,
        alertMessage, setAlertMessage,
        alertSeverity, setAlertSeverity
      }}>
        {renderPage()}
      </AlertDataContext.Provider>
    </AppBarDataContext.Provider>
  );
}

MainPage.propTypes = {
  currentUser: PropTypes.oneOf(Object.keys(RoleEnum)).isRequired,
  serverStatus: PropTypes.oneOf(Object.keys(ServerStatusEnum)).isRequired,
  appName: PropTypes.string.isRequired,
  appLoaded: PropTypes.bool.isRequired
}

const UserPage = ({currentUser, serverStatus, appName}) => {
  const {isAlert, alertMessage, alertSeverity} = useContext(AlertDataContext);
  return (
    <Routes>
      {navbarRoutes(currentUser, serverStatus, appName, alertMessage, isAlert, alertSeverity)}
      {notFoundRoutes}
    </Routes>
  )
}

UserPage.propTypes = {
  currentUser: PropTypes.oneOf(Object.keys(RoleEnum)).isRequired,
  serverStatus: PropTypes.oneOf(Object.keys(ServerStatusEnum)).isRequired,
  appName: PropTypes.string.isRequired
}

const AdmPage = ({currentUser, serverStatus, appName}) => {
  const navigate = useNavigate();
  const {isAlert, alertMessage, alertSeverity} = useContext(AlertDataContext);

  // Redirect if the serverStatus is UNLOCKED and user tried to access /adm
  useEffect(() => {
    if (serverStatus !== ServerStatusEnum.UNCONFIGURED) {
      navigate('/');
    }
  }, [serverStatus, navigate]);

  return (
    <Routes>
      {navbarAdmRoutes(currentUser, serverStatus, appName, alertMessage, isAlert, alertSeverity)}
      {notFoundRoutes}
    </Routes>
  );
}

AdmPage.propTypes = {
  currentUser: PropTypes.oneOf(Object.keys(RoleEnum)).isRequired,
  serverStatus: PropTypes.oneOf(Object.keys(ServerStatusEnum)).isRequired,
  appName: PropTypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
