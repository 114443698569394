import React from "react";
import RevokeCertificate from "../../../revoke/components/revoke_certificate";
import PropTypes from "prop-types";
import DownloadZip from "./download_zip";

const getSpecificBatchButton = (
  certificates,
  selected,
  reason,
  code,
  setCertErrors
) => ({
  download: <DownloadZip files={getZip(certificates, selected)} />,
  revoke: (
    <RevokeCertificate
      serialNumbers={selected}
      code={code}
      reason={reason}
      setCertErrors={setCertErrors}
    />
  ),
});

function BatchCertificateButton({
  type,
  certificates,
  setCertErrors,
  selected,
  message,
  code,
}) {
  return (
    <div>
      {
        getSpecificBatchButton(
          certificates,
          selected,
          message,
          code,
          setCertErrors
        )[type]
      }
    </div>
  );
}

BatchCertificateButton.propTypes = {
  type: PropTypes.string.isRequired,
  certificates: PropTypes.array.isRequired,
  setCertErrors: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  message: PropTypes.string.isRequired,
  code: PropTypes.number.isRequired,
};

function getZip(certificates, selected) {
  return pemsToFiles(getPems(certificates, selected));
}

function getPems(certificates, selected) {
  return getSelectedSerialRows(certificates, selected).map((el) => [
    el.serialNumber,
    el.pem,
  ]);
}

const getSelectedSerialRows = (certificates, selected) => {
  return certificates.filter((el) => {
    return selected.includes(el.serialNumber);
  });
};

const pemsToFiles = (pemArray) => {
  return pemArray.map((el) => getCertFile(el[0], el[1]));
};

const getCertFile = (serialNumber, pem) => {
  return new File([pem], "certificate" + serialNumber + ".crt", {
    type: "application/pkix-cert",
  });
};

export default BatchCertificateButton;
