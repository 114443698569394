export const CERTIFICATE_ADMINISTRATOR =
  // eslint-disable-next-line max-len
  "-----BEGIN CERTIFICATE-----\nMIIBrjCCAVSgAwIBAgIBATAKBggqhkjOPQQDAjAeMRwwGgYDVQQDDBNZYnlyYSBBZG1pbmlzdHJhdG9yMCAXDTIyMDMyMzEzNDAzOFoYDzIwNTIwMzE1MTM0MDM4WjAeMRwwGgYDVQQDDBNZYnlyYSBBZG1pbmlzdHJhdG9yMFYwEAYHKoZIzj0CAQYFK4EEAAoDQgAEbspl5PCK6p6AukN+HWSNsOZZSBxESlQxTwlKGopkg9cp0BsJ8AbDa6qaL+jfmmoOjjhKKHF4U89YOfhkrajMNaOBgzCBgDBjBgNVHSMEXDBagFgwVjAQBgcqhkjOPQIBBgUrgQQACgNCAARuymXk8IrqnoC6Q34dZI2w5llIHERKVDFPCUoaimSD1ynQGwnwBsNrqpov6N+aag6OOEoocXhTz1g5+GStqMw1MAkGA1UdEwQCMAAwDgYDVR0PAQH/BAQDAgTwMAoGCCqGSM49BAMCA0gAMEUCID9t5E4UxSa6NlqPi9RRiMRctbgU2ak+/Sp6wfioOaYkAiEAvFH/jqq3MlYcDA4I6GXMk7ICpmcOPLkBA+BF8LBOd6A=\n-----END CERTIFICATE-----";
export const SIGNATURE_ADMINISTRATOR =
  "MEQCIBDvlEYy4apMiRxB0WXdFnaC2FCxdwtbYPJIu38nOmBlAiBqHZUy4Dm6o2+b4VJi4n22ltP5OBOr2zNW2PA/kypPeQ==";
export const ALGORITHM_ADMINISTRATOR = "SHA256WithECDSA";
export const ROLE_ADMINISTRATOR = "ADMINISTRATOR";

export const CERTIFICATE_OPERATOR =
  // eslint-disable-next-line max-len
  "-----BEGIN CERTIFICATE-----\nMIIBozCCAUqgAwIBAgIBATAKBggqhkjOPQQDAjAZMRcwFQYDVQQDDA5ZYnlyYSBPcGVyYXRvcjAgFw0yMjAzMjMxMzQyMjhaGA8yMDUyMDMxNTEzNDIyOFowGTEXMBUGA1UEAwwOWWJ5cmEgT3BlcmF0b3IwVjAQBgcqhkjOPQIBBgUrgQQACgNCAARuymXk8IrqnoC6Q34dZI2w5llIHERKVDFPCUoaimSD1ynQGwnwBsNrqpov6N+aag6OOEoocXhTz1g5+GStqMw1o4GDMIGAMGMGA1UdIwRcMFqAWDBWMBAGByqGSM49AgEGBSuBBAAKA0IABG7KZeTwiuqegLpDfh1kjbDmWUgcREpUMU8JShqKZIPXKdAbCfAGw2uqmi/o35pqDo44SihxeFPPWDn4ZK2ozDUwCQYDVR0TBAIwADAOBgNVHQ8BAf8EBAMCBPAwCgYIKoZIzj0EAwIDRwAwRAIgecqgQFhzZHuwHYnkfR5KZGXCDM0/0DIU/9fJNSOpQlACIAi0RpcSeIQstqs/r3+AnPL4reCFhEWetgXkk00BBvTJ\n-----END CERTIFICATE-----";
export const DATABASE_FORM = {
  //TODO change this to a valid test cpf
  cpfCnpj : ""
};

export const CSV_EXAMPLE_DATA = () => {return {
  "header": ["cn", "c", "st", "l", "ou", "o", "c"],
  "rows": [
    "Nicole Freitas,BR,SC,Florianopolis,LabSEC,UFSC,UK",
    "Adriano Mauro,BR,SP,Sao Paulo,,USP,US",
    "Lucas Matheus,FR,PB,Cidade,LabSEC,UFSC,BR",
    "Lucas Matheus,BR,SC,Florianopolis,LabSEC,UFSC,UK"
  ]
}}
