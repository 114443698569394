import React from "react";
import {Outlet, Route} from "react-router-dom";
import {Box} from "@material-ui/core";
import {ResponsiveAppBar} from "./appbar";
import {Alerter} from "../../common/components/alerter";
import welcomeRoutes from "../../welcome/route";
import templateRoutes from "../../template/route";
import certificateRoutes from "../../certificate/route";
import revokeRoutes from "../../revoke/route";
import changeRoleRoutes from "../../role_change/route";
import loginRoutes from "../../login/route";
import admRoutes from "../../adm/route";
import createUserRoute from "../../users/create/route";
import manageUserRoute from "../../users/manage/route";
import createTemplateRoutes from "../../template/create/route";
import listTemplateRoutes from "../../template/list/route";
import issueCSVRoutes from "../../issue/csv/route";
import issueDNRoutes from "../../issue/dn/route";

export const navbarRoutes = (currentUser, serverStatus, appName, alertMessage, isAlert, alertSeverity) => {
  return (<Route
    path="/"
    element={
      <Box>
        <ResponsiveAppBar
          currentUser={currentUser}
          serverStatus={serverStatus}
          appName={appName}
        />
        <Alerter alertMessage={alertMessage} isAlert={isAlert} alertSeverity={alertSeverity}></Alerter>
        <Outlet />
      </Box>
    }
  >
    {welcomeRoutes}
    {loginRoutes}
    {templateRoutes}
    {createTemplateRoutes}
    {listTemplateRoutes}
    {issueCSVRoutes}
    {issueDNRoutes}
    {certificateRoutes}
    {revokeRoutes}
    {createUserRoute}
    {manageUserRoute}
    {changeRoleRoutes}
    {admRoutes}
  </Route>)
}

export const navbarAdmRoutes = (currentUser, serverStatus, appName, alertMessage, isAlert, alertSeverity) => {
  return (<Route
    path="/"
    element={
      <Box>
        <ResponsiveAppBar
          currentUser={currentUser}
          serverStatus={serverStatus}
          appName={appName}
        />
        <Alerter alertMessage={alertMessage} isAlert={isAlert} alertSeverity={alertSeverity}></Alerter>
        <Outlet />
      </Box>
    }
  >
    {welcomeRoutes}
    {admRoutes}
  </Route>)
}

