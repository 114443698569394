import {Route} from "react-router-dom";
import React from "react";
import {TemplateList} from "./template_list";

const listTemplateRoutes = (
  <React.Fragment>
    <Route path="templates/manage" element={<TemplateList/>} />
  </React.Fragment>
)

export default listTemplateRoutes;
