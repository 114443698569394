import {Route} from "react-router-dom";
import React from "react";
import IssueCertificateByDnComponent from "./issue_dn";

const issueDNRoutes = (
  <React.Fragment>
    <Route path="issue/dn" element={<IssueCertificateByDnComponent />} />
  </React.Fragment>
)

export default issueDNRoutes;
