import {Route} from "react-router-dom";
import React from "react";
import CertviewComponent from "../common/components/certificate_list/certificate_lister";

const revokeRoutes = (
  <React.Fragment>
    <Route path="certificates/revoke" element={<CertviewComponent type="revoke"/>} />
  </React.Fragment>
)

export default revokeRoutes;
