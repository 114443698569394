import superagentPromise from "superagent-promise";
import { parse } from 'lossless-json';
import _superagent from "superagent";

const superagent = superagentPromise(_superagent, global.Promise);

class Requests {
  constructor(api) {
    this.api = api;
    this.token = null;
    this.refreshToken = null;
    this.useRefreshToken = false;

    this.del = this.del.bind(this);
    this.get = this.get.bind(this);
    this.get_octet_stream = this.get_octet_stream.bind(this);
    this.post = this.post.bind(this);
    this.setToken = this.setToken.bind(this);
    this.tokenHandler = this.tokenHandler.bind(this);
    this.getToken = this.getToken.bind(this);
    this.setRequisition = this.setRequisition.bind(this);
  }

  setRequisition(req, headers, query, token){
    req.query(query ? query : {})
      .set(headers ? headers : {})
      .set("Authorization", `Bearer ${ token ? token : this.getToken()}`);
  }

  async tokenHandler(func) {
    let req = {};
    try{
      req = await func();
    } catch (e) {
      if (e.response && e.response.status === 401){
        const refresh = await superagent.post(`${this.api}/user/refreshtoken`, {})
          .set("Authorization", `Bearer ${this.refreshToken}`);
        if (refresh.status !== 401){
          this.setToken(refresh.body.accessToken, this.refreshToken)
          req = await func(this.getToken());
          this.setUseRefreshToken(false);
        }
        return req.body;
      }
      throw e;
    }
    return req.body;
  }

  getToken() {
    if (this.useRefreshToken) {
      this.useRefreshToken = false;
      return this.refreshToken;
    }
    return this.token;
  }

  del(url, body, header, query) {
    return this.tokenHandler((token) => superagent
      .del(`${this.api}${url}`, body)
      .use((req) => {
        this.setRequisition(req, header, query, token);
      }));
  }

  get(url, header, query) {
    return this.tokenHandler((token) => superagent
      .get(`${this.api}${url}`)
      .use((req) => {
        this.setRequisition(req, header, query, token);
      }).end((err, res) => {
        if (res.body.content) {
          res.body.content = parse(res.text, null, (num) => {
            return num.isLosslessNumber ? num.value : num;
          }).content;
        }}))
  }

  get_octet_stream(url, header, query) {
    return this.tokenHandler((token) => superagent
      .get(`${this.api}${url}`)
      .use((req) => {
        this.setRequisition(req, header, query, token);
      })
      .responseType("blob"));
  }

  put(url, body, header, query) {
    return this.tokenHandler((token) => superagent
      .put(`${this.api}${url}`, body)
      .use((req) => {
        this.setRequisition(req, header, query, token);
      }));
  }

  post(url, body, header, query) {
    return this.tokenHandler((token) => superagent
      .post(`${this.api}${url}`, body)
      .use((req) => {
        this.setRequisition(req, header, query, token);
      }));
  }

  setToken(_token, refreshToken) {
    this.token = _token;
    this.refreshToken = refreshToken;
  }
  setUseRefreshToken(useRefreshToken) {
    this.useRefreshToken = useRefreshToken;
  }
}

export default Requests;
