import TemplateListComponent from "./components/template_grid";
import React from "react";
import {YbyraContainer} from "../../common/style";

export const TemplateList = () => {
  return (
    <YbyraContainer>
      <h2>Manage Templates</h2>
      <TemplateListComponent isEditable={true}/>
    </YbyraContainer>)
}
