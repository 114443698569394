import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridActionsCellItem, GridRowModes} from '@mui/x-data-grid';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import {
  handleCancelClick,
  handleEditClick, handleRowEditStop,
  handleRowModesModelChange,
  handleSaveClick, processRowUpdate
} from "../../../common/functions/list_functions";
import PropTypes from "prop-types";

export const AccessDescriptionList = ({rows, setRows}) => {
  const [rowModesModel, setRowModesModel] = React.useState({});

  const getActions = (id) => {
    id = id.id;
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

    if (isInEditMode) {
      return [
        <GridActionsCellItem
          key="save"
          icon={<SaveIcon />}
          label="Save"
          sx={{
            color: 'primary.main',
          }}
          onClick={handleSaveClick(id, rowModesModel, setRowModesModel)}
        />,
        <GridActionsCellItem
          key="cancel"
          icon={<CancelIcon />}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(id, rows, setRows, rowModesModel, setRowModesModel)}
          color="inherit"
        />,
      ];
    }

    return [
      <GridActionsCellItem
        key="edit"
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(id, rowModesModel, setRowModesModel)}
        color="inherit"
      />
      // Reabilitar quando exclusão for readicionada
      // <GridActionsCellItem
      // 	icon={<DeleteIcon />}
      // 	label="Delete"
      // 	onClick={handleDeleteClick(id, rows, setRows)}
      // 	color="inherit"
      // />,
    ];
  }

  const columns = [
    {
      field: 'value',
      headerName: 'Access Description Base URI',
      width: 225,
      editable: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 75,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: getActions,
    },

  ];

  return (
    <Box sx={{ height: 260, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 3,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick

        onRowModesModelChange={(newModel) => handleRowModesModelChange(newModel, setRowModesModel)}
        processRowUpdate={(newRow) => processRowUpdate(newRow, rows, setRows)}
        rowModesModel={rowModesModel}
        onRowEditStop={handleRowEditStop}

      />
    </Box>
  );
}
AccessDescriptionList.propTypes = {
  rows: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired
}