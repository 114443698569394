import {
  GET_CRL, BUTTON_CLICK, UPDATE_ISSUER
} from '../features/common/components/certificate_list/action_type';

const defaultState = {
  crlGot: false,
  issuer: 0
};

const Crl = (state = defaultState, action) => {
  switch (action.type) {
  case UPDATE_ISSUER:
    return {...state, issuer: action.value}
  case BUTTON_CLICK:
    return {...state, crlGot: false};
  case GET_CRL:
    return {...state, crlGot: true};
  default:
    return state;
  }

};

export default Crl;
