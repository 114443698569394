import {
  UPDATE_FIELD_TEMPLATE,
  ADD_TEMPLATE_ATTRIBUTE,
  TEMPLATE_CREATE_LOAD,
  TEMPLATE_CREATE_SUCCESSFUL,
  TEMPLATE_CREATE_UNSUCCESSFUL,
  REMOVE_ATTRIBUTE_TEMPLATE,
  CHANGE_ADVANCED_OPTIONS_VISIBILITY
} from '../features/common/components/certificate_list/action_type';

const defaultState = {
  name: null,
  attributes: [],
  aCTargetsSet: [],
  noRevocationAvailable: false,
  accessDescriptionsSet: [],
  advancedOptionsVisible: false,
  publicTemplate: false,
};

const Template = (state = defaultState, action) => {
  switch (action.type) {
  case ADD_TEMPLATE_ATTRIBUTE:
    return { ...state, [action.key]: [...state[action.key], action.value]}
  case UPDATE_FIELD_TEMPLATE:
    return {...state, [action.key]: action.value};
  case REMOVE_ATTRIBUTE_TEMPLATE:
    for (let i = 0; i < action.value.length; i++) {
      const index = state[action.key].indexOf(action.value[i]);
      if (index > -1) {
        state[action.key].splice(index, 1);
      }
    }
    return {...state}
  case TEMPLATE_CREATE_LOAD:
    return {
      defaultState
    };
  case TEMPLATE_CREATE_SUCCESSFUL:
    return {
      defaultState
    };
  case TEMPLATE_CREATE_UNSUCCESSFUL:
    return {
      defaultState
    };
  case CHANGE_ADVANCED_OPTIONS_VISIBILITY:
    return {
      ...state, advancedOptionsVisible: !state.advancedOptionsVisible
    };
  default:
    return state;
  }

};

export default Template;
