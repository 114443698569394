import Requests from "./requests";
import {env} from '../../../env'

const API_ROOT = env.REACT_APP_BACKEND + "/ybyra-engine";

const requests = new Requests(API_ROOT);

const Auth = {
  login: (userIdentifier) =>
    requests.post(
      "/user/login",
      {
        userIdentifier: userIdentifier
      },
      { "Content-Type": "application/json" }
    ),
  register: (username, email, password) =>
    requests.post("/user", { user: { username, email, password } }),
};

const Template = {
  //operator
  set: (template, attributes) =>
    requests.put(
      "/template/" + template,
      attributes,
      { "Content-Type": "application/json" },
      template
    ),
  //operator
  create: (templateName, attributes) =>
    requests.post(
      "/template",
      attributes,
      { "Content-Type": "application/json" },
      templateName
    ),
  //operator
  list: (request) =>
    requests.get("/template", { "Content-Type": "application/json" }, request),
  //operator
  getById: (templateId) => requests.get("/template/" + templateId, { "Content-Type": "application/json" }, {}),
  update: (request, templateId) => requests.put("/template/"+ templateId, request),
  //operator
  addAuthorizationFields: (templateName, authorizationFields) =>
    requests.post(
      "/template/" + templateName + "/authorized-field",
      authorizationFields,
      { "Content-Type": "application/json" },
      templateName
    ),
};

const Server = {
  status: async () => {
    try {
      return await requests.get(`/system/status`);
    } catch (e) {
      return {
        status: 'OFFLINE',
        error: e
      };
    }
  },
  config: (request) =>
    requests.post("/system/config", request, {
      "Content-Type": "application/json",
    }),
  unlock: (request) =>
    requests.post("/system/unlock", request, {
      "Content-Type": "application/json",
    }),
  delete: (request) =>
    requests.del("/system/configuration", request, {
      "Content-Type": "application/json",
    }),
};


const User = {
  //Administrator
  //Maybe this CORS headers should be removed
  create: (request) =>
    requests.post(`/user/signup`, request, {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*, http://192.168.66.207:9080/ybyra-engine/user/signup",
      "Access-Control-Allow-Headers": "*, http://192.168.66.207:9080/ybyra-engine/user/signup",
      "Access-Control-Allow-Credentials": "true",
      Accept: "application/json"
    }),
  //Administrator
  list: (request) => requests.get("/user", { "Content-Type": "application/json" }, request),
  //Administrator
  update: (userId, active) => requests.put("/user/" + userId, { isActive: active }),
  //Administrator
  updateActivateStatus: (userId, request) =>
    requests.put("/user/" + userId + "/active-status", { request }),
  listUserRoles: () => {
    return requests.get("/user/get-roles")},
  changeRole: (userRole) => {
    requests.setUseRefreshToken(true);
    return (requests.post(
      "/user/change-role",
      {
        userRole: userRole
      },
      { "Content-Type": "application/json" }
    ))
  }
};

const SigningConfiguration = {
  register: (request) =>
    requests.post("/user/signature-provider", request, {
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
  configure: (request) =>
    requests.put("/user/signature-provider", request, {
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
};

const Certificate = {
  create: (request) =>
    requests.post(`/attr-certificate/cert`, request, {
      "Content-Type": "application/json",
    }),
  //Operator
  issue_by_csv: (request) =>
    requests.post("/attr-certificate/csv", request, {
      "Content-Type": "application/json",
    }),
  //Operator
  issue_by_dn: (request) =>
    requests.post("/attr-certificate/dn", request, {
      "Content-Type": "application/json",
    }),
  templates: (certificate) =>
    requests.post("/template/cert", certificate, {
      "Content-Type": "application/json",
    }),
  list: (request) => requests.get(`/attr-certificate/list`, {
    "Content-Type": "application/json",
  }, request),
};

const Revoke = {
  //Operator
  revoke: (request) =>
    requests.post(`/revoke`, request, {
      "Content-Type": "application/json",
    }),
  crl: (query) =>
    requests.get_octet_stream(
      "/crl",
      { Accept: "application/octet-stream" },
      query
    ),
  //Operator
  issue_crl: () => requests.post("/crl", null, {}),
};


const Api = () => API_ROOT;

const Agent = {
  Template,
  Auth,
  Server,
  User,
  Certificate,
  Revoke,
  SigningConfiguration,
  Api,
  setToken: (_token, refreshToken) => {
    requests.setToken(_token, refreshToken);
  },
};

export default Agent;
