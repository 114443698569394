import {ybyraContainerStyles} from "../../styles/base-css";
import React from 'react';
import PropTypes from "prop-types";

export const YbyraBackground = ({children}) => {
  const styles = ybyraContainerStyles();
  return <div className={styles.background}> {children} </div>
}

export const YbyraContainer = ({children}) => {
  const styles = ybyraContainerStyles();
  return <div className={styles.container}> {children} </div>
}

export const YbyraFlex = ({children}) => {
  const styles = ybyraContainerStyles();
  return <div className={styles.flex}> {children} </div>
}
YbyraBackground.propTypes = {
  children: PropTypes.node.isRequired,
};

YbyraContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

YbyraFlex.propTypes = {
  children: PropTypes.node.isRequired,
};
