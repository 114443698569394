import React from "react";
import {Route} from "react-router-dom";
import UserGrid from "./manage";

const manageUserRoute = (
  <React.Fragment>
    <Route path="users/manage" element={<UserGrid />} />
  </React.Fragment>
)

export default manageUserRoute;
