import React from 'react';
import Container from "@material-ui/core/Container";
import {Paper} from "@material-ui/core";
import {mainStyle} from "../../../styles/base-css";
import PropTypes from "prop-types";

const BaseSubNavBarComponent = (props) => {
  const classes = mainStyle();
  return (<AllowanceBaseSubNavBarComponent classes={classes} {...props} />);
};

class AllowanceBaseSubNavBarComponent extends React.Component {
  constructor(props) {
    super(props);

    this.roleRender = this.props.roleRender;
    this.renderRoutes = this.props.renderRoutes;
  }

  render() {
    return (
      <React.Fragment>
        <Paper className={this.props.classes.subBar}>
          <Container>
            {this.roleRender()}
          </Container>
        </Paper>
        <Paper className={this.props.classes.paper}>
          <Container>
            {this.renderRoutes()}
          </Container>
        </Paper>
      </React.Fragment>
    );
  }
}

AllowanceBaseSubNavBarComponent.propTypes = {
  roleRender: PropTypes.func.isRequired,
  renderRoutes: PropTypes.func.isRequired,
  classes: PropTypes.any.isRequired
}

export default BaseSubNavBarComponent;
