import Agent from './features/common/communication/agent';
import {ASYNC_END, ASYNC_START, LOGIN, LOGOUT, REGISTER}
  from './features/common/components/certificate_list/action_type';

const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    store.dispatch({type: ASYNC_START, subtype: action.type});

    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    action.payload.then(
      res => {
        const currentState = store.getState()
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return
        }
        action.payload = res;
        store.dispatch({type: ASYNC_END, promise: action.payload});
        store.dispatch(action);
      },
      error => {
        const currentState = store.getState()
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return
        }
        action.error = true;
        action.payload = error.response.body;
        if (!action.skipTracking) {
          store.dispatch({type: ASYNC_END, promise: action.payload});
        }
        store.dispatch(action);
      }
    );

    return;
  }

  next(action);
};

const localStorageMiddleware = () => next => action => {
  if (action.type === REGISTER || action.type === LOGIN) {
    if (!action.error) {
      window.localStorage.setItem('jwt', action.payload.token);
      window.localStorage.setItem('refreshToken', action.payload.refreshToken);
      window.localStorage.setItem('role', action.payload.role);
      Agent.setToken(action.payload.token, action.payload.refreshToken);
    }
  } else if (action.type === LOGOUT) {
    window.localStorage.setItem('jwt', '');
    window.localStorage.setItem('refreshToken', '');
    window.localStorage.setItem('role', '');
    Agent.setToken(null, null);
  }

  next(action);
};

function isPromise(v) {
  return v && typeof v.then === 'function';
}


export {promiseMiddleware, localStorageMiddleware}
