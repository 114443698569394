import React from "react";
import PropTypes from "prop-types";
const CSVSelector = ({ onChange }) => {
  const handleFileChange = async (e) => {
    if (e.target.files) {
      try {
        const file = e.target.files[0];
        const response = await fetch(URL.createObjectURL(file));
        const text = await response.text();
        onChange(text);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return <input type="file" className="form-control" accept=".csv" onChange={handleFileChange} />;
};

CSVSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default CSVSelector;
