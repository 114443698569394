import {Alert} from "@mui/material";
import React, {createContext, useContext} from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import {UNKNOWN_ERROR} from "../constants/errors";

export const Alerter = ({alertMessage, alertSeverity, isAlert}) => {
  const context = useContext(AlertDataContext);

  const handleClose = () => {
    context.setIsAlert(false);
  };

  let autoHideDuration = 10000;
  return (
    <Snackbar anchorOrigin={{horizontal:"center", vertical:"top"}}
      open={isAlert} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}

Alerter.propTypes = {
  alertMessage: PropTypes.string.isRequired,
  alertSeverity: PropTypes.string.isRequired,
  isAlert: PropTypes.bool.isRequired,
}

const setAlert = (message, severity, context) => {
  const {setIsAlert, setAlertMessage, setAlertSeverity} = context;
  setAlertMessage(message);
  setAlertSeverity(severity);
  setIsAlert(true);
}

export const handleError = (error, context) => {
  let errorMessage = error.response.body.message;
  setErrorAlert(errorMessage ? errorMessage : UNKNOWN_ERROR, context);
}

export const setErrorAlert = (message, context) => {
  setAlert(message, "error", context)
}

export const setSuccessAlert = (message, context) => {
  setAlert(message, "success", context)
}

export const setWarningAlert = (message, context) => {
  setAlert(message, "warning", context)
}

export const AlertDataContext = createContext(
  {
    isAlert: false, setIsAlert: () => {},
    alertMessage: "", setAlertMessage: () => {},
    transitioning: false, setTransitioning: () => {}
  }
);
