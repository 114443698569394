import React from "react";
import {Route} from "react-router-dom";
import CreateUserFormComponent from "./create";

const createUserRoute = (
  <React.Fragment>
    <Route path="users/create" element={<CreateUserFormComponent />} />
  </React.Fragment>
)

export default createUserRoute;
