import React, {Fragment, useContext, useEffect} from 'react';
import {
  DataGrid,
  getGridStringOperators,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport, GridToolbarFilterButton
} from '@mui/x-data-grid';
import Agent from "../../../common/communication/agent";
import {AlertDataContext, setErrorAlert} from "../../../common/components/alerter";
import {TEMPLATE_LIST_ERROR} from "../../../common/constants/errors";
import {Button} from "@material-ui/core";
import TemplateEditModal from "./template_edit";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";


const TemplateGrid = ({ isEditable, setParentTemplateId = () => {} } ) => {

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const [open, setOpen] = React.useState(false);

  const alertContext = useContext(AlertDataContext);

  const [payload, setPayload] = React.useState([]);

  const [templateId, setTemplateId] = React.useState(-1);

  const [rowCountState, setRowCountState] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState([5, 10, 20, 50, 100]);

  const [filter, setFilter] = React.useState([]);

  const filterOperators = getGridStringOperators().filter(({value}) =>
    ['equals'].includes(value),
  );

  const [editData, setEditData] = React.useState({});

  const requestEditData = async (cellValues) => {

    try {
      setTemplateId(cellValues.row.id);
      const response = await Agent.Template.getById(cellValues.row.id);
      setEditData(response);
      setOpen(true);
    } catch (error) {
      setErrorAlert(TEMPLATE_LIST_ERROR, alertContext);
    }

  }


  const renderEditButton = (cellValues) => {
    return (
      <Button  startIcon={<EditIcon />}
        onClick = {() => {requestEditData(cellValues).then( () =>
        {   });
        }}>

      </Button>
    );
  }


  const getColumns = () => {
    const columns = [
      {
        flex: 0.2, renderCell: renderEditButton
      },
      {field: 'id', headerName: "Id", flex: 1, filterOperators},
      {field: 'name', headerName: "Template Name", flex: 1, filterOperators},
      {field: 'publicTemplate', headerName: "Public Template", flex: 1, type: 'boolean'}
    ];
    return isEditable ? columns : columns.slice(1);
  }



  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const generatePageSizeOptions = (totalRows) => {
    const pageCount = Math.ceil(totalRows / 5);
    return Array.from({ length: pageCount }, (_, index) => (index + 1) * 5)
      .filter(option => option <= 15);
  }



  const doRequest = async () => {
    const page = {};
    page.page = paginationModel.page;
    page.size = paginationModel.pageSize;
    const params = Object.assign({}, page, filter);
    try {
      const response = await Agent.Template.list(params);
      setPayload(response.content);
      setRowCountState(response.totalElements);
      const pageSizeOp = generatePageSizeOptions(response.totalElements);
      setRowsPerPage(pageSizeOp);
    } catch (error) {
      setErrorAlert(TEMPLATE_LIST_ERROR, alertContext);
    }
  }

  const onFilterChange = React.useCallback((filterModel) => {
    var filter = {};
    filterModel.items.forEach( f => {
      filter[f.field] = f.value;
      setFilter(filter);
    });
    setFilter(filter);
  }, []);


  useEffect(() => { doRequest(); }, [paginationModel.page, paginationModel.pageSize, filter]);

  const handleSelectionChange = (newSelection) => {
    setTemplateId(newSelection.at(0));
    setParentTemplateId(newSelection.at(0));
  };

  return (
    <Fragment>
      <TemplateEditModal
        open={open} setOpen={setOpen} payload={editData} setPayload={setEditData} templateId={templateId}/>
      <DataGrid keepNonExistentRowsSelected
        rows={payload}
        slots={{toolbar: CustomToolbar}}
        rowCount={rowCountState}
        loading={false}
        pageSizeOptions={rowsPerPage}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        columns={getColumns()}
        onRowSelectionModelChange={handleSelectionChange}
      />
    </Fragment>
  );

}

TemplateGrid.propTypes = {
  setParentTemplateId: PropTypes.func,
  isEditable: PropTypes.bool.isRequired
};

export default TemplateGrid;
