import {
  APP_LOAD,
  DEV_MODE_CHANGE,
  LOGIN,
  LOGIN_PAGE_LOADED,
  LOGIN_PAGE_UNLOADED,
  LOGOUT,
  REDIRECT,
  REGISTER,
  REGISTER_PAGE_UNLOADED,
  SETTINGS_SAVED,
  SET_MESSAGE,
  SYSTEM_CONFIG,
  SYSTEM_UNLOCK,
  SYSTEM_UNLOCK_FAILURE,
  UPDATE_AVAILABLE_ROLES,
  UPDATE_ROLE_TO_CHANGE,
} from "../features/common/components/certificate_list/action_type";
import {
  ALGORITHM_ADMINISTRATOR,
  CERTIFICATE_ADMINISTRATOR,
  ROLE_ADMINISTRATOR,
  SIGNATURE_ADMINISTRATOR,
} from "../features/common/constants/dev_artifacts";
import { RoleEnum, ServerStatusEnum } from "../features/common/constants/enums";

const defaultState = {
  appName: "Ybyra",
  token: null,
  refreshToken: null,
  certificatePem: CERTIFICATE_ADMINISTRATOR,
  base64Signature: SIGNATURE_ADMINISTRATOR,
  signatureAlgorithm: ALGORITHM_ADMINISTRATOR,
  userRole: ROLE_ADMINISTRATOR,
  currentUser: RoleEnum.NONE,
  serverStatus: ServerStatusEnum.UNCHECKED,
  appLoaded: false,
  devMode: false,
  availableRoles: [""],
  roleToChange: "",
  message: "",
};

const Common = (state = defaultState, action) => {
  switch (action.type) {
  case APP_LOAD:
    return {
      ...state,
      token: action.token || null,
      refreshToken: action.refreshToken || null,
      appLoaded: true,
      currentUser: action.payload.user
        ? action.payload.user
        : state.currentUser,
      serverStatus: action.payload.serverStatus,
    };
  case REDIRECT:
    return { ...state, redirectTo: null };
  case LOGOUT:
    return {
      ...state,
      redirectTo: "/",
      token: null,
      refreshToken: null,
      currentUser: RoleEnum.NONE,
    };
  case SETTINGS_SAVED:
    return {
      ...state,
      redirectTo: action.error ? null : "/",
      currentUser: action.error ? RoleEnum.NONE : action.payload.user,
    };
  case LOGIN:
    return {
      ...state,
      redirectTo: action.error ? null : "/",
      token: action.error ? null : action.payload.token,
      refreshToken: action.error ? null : action.payload.refreshToken,
      currentUser: action.error ? state.userRole : action.payload.role,
    };
  case UPDATE_AVAILABLE_ROLES:
    return {
      ...state,
      availableRoles: action.payload,
    };
  case UPDATE_ROLE_TO_CHANGE:
    return {
      ...state,
      roleToChange: action.value,
    };
  case REGISTER:
  case LOGIN_PAGE_LOADED:
    return {
      ...state,
      redirectTo: "/login",
    };
  case LOGIN_PAGE_UNLOADED:
  case REGISTER_PAGE_UNLOADED:
    return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
  case SYSTEM_UNLOCK:
  case SYSTEM_CONFIG:
    return {
      ...state,
      redirectTo: action.error ? null : "/",
      serverStatus: action.payload,
      errors: action.error ? action.error : null,
    };
  case SYSTEM_UNLOCK_FAILURE:
    return {
      ...state,
      serverStatus: ServerStatusEnum.UNLOCKED,
      errors: action.error ? action.error : null,
    };
  case DEV_MODE_CHANGE:
    return {
      ...state,
      devMode: action.payload,
    };
  case SET_MESSAGE:
    return {
      ...state,
      message: action.value,
    };
  default:
    return state;
  }
};

export default Common;
