import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ServerStatusEnum} from "../common/constants/enums";
import SystemConfigurationComponent from "./components/system_configuration";
import {REDIRECT} from "../common/components/certificate_list/action_type";
import {connect} from "react-redux";
import PropTypes from "prop-types";

const mapStateToProps = state => {
  return {
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
    serverStatus: state.common.serverStatus
  }
};

const mapDispatchToProps = dispatch => ({
  onRedirect: () =>
    dispatch({type: REDIRECT})
});

class AdmComponent extends React.Component {
  componentDidMount() {
  }

  render() {
    return(<SystemConfigurationComponent/>)
  }

}

AdmComponent.propTypes = {
  serverStatus: PropTypes.oneOf(Object.keys(ServerStatusEnum)).isRequired,
  backEndHost: PropTypes.string.isRequired
}


export default connect(mapStateToProps, mapDispatchToProps)(AdmComponent);
