import React from "react";
import {CERTIFICATE_ZIP_FILE_NAME} from "../../constants/string_defaults";
import { saveAs } from 'file-saver';
import { PropTypes } from "prop-types";

const DownloadZip = (props) => {
  const handleClick = () => {
    let zip = require('jszip')();
    props.files.forEach((file) => {
      zip.file(file.name, file);
    })
    zip.generateAsync({type: "blob"}).then(content => {
      saveAs(content, CERTIFICATE_ZIP_FILE_NAME);
    });
  }

  return (
    <button
      className="btn btn-primary"
      onClick={handleClick}
    >
      Download ZIP
    </button>
  )
}

DownloadZip.propTypes = {
  files : PropTypes.array.isRequired,
};

export default DownloadZip;