import React, {useContext} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {connect} from "react-redux";
import {Box, Button} from "@material-ui/core";
import {mainStyle} from "../../styles/base-css";
import PropTypes from "prop-types";
import {ServerStatusContext} from "../common/components/server_status";
import {ServerStatusEnum} from "../common/constants/enums";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {Circle} from "@mui/icons-material";
import {YbyraContainer, YbyraFlex} from "../common/style";
import {YkueDownloadButton} from "../common/components/ykue_download";
import {env} from "../../env";

const WelcomeComponent = (props) => {
  const classes = mainStyle();
  return (<AllowanceWelcomeComponent classes={classes} {...props} />);
};

const mapStateToProps = state => {
  return {
    appName: state.common.appName
  }
};

const mapDispatchToProps = () => ({});

const AllowanceWelcomeComponent = ({classes}) => {

  const serverStatus = useContext(ServerStatusContext);

  const getColorByStatus = (status) => {
    switch (status) {
    case ServerStatusEnum.UNCONFIGURED:
      return 'orange';
    case ServerStatusEnum.UNLOCKED:
      return 'green';
    default:
      return 'gray'; // default color
    }
  };

  const getFriendlyStatus = (status) => {
    switch (status) {
    case ServerStatusEnum.UNCONFIGURED:
      return 'unconfigured';
    case ServerStatusEnum.UNLOCKED:
      return 'online';
    default:
      return 'offline'; // default color
    }
  };

  return (
    <YbyraContainer>
      <Box display="flex" alignItems="center" sx={{marginTop:"0px"}} mt={2}>
        <h1>Ybyrá </h1>

        <IconButton
          style={{ color: getColorByStatus(serverStatus) }}>
          <Circle />
        </IconButton>
        <Typography variant="body1">
          {getFriendlyStatus(serverStatus)}
        </Typography>
      </Box>

      <YbyraFlex>

        <Button
          variant="contained"
          className={classes.button}
          href={env.REACT_APP_ICP_BRASIL_DOCS}
          target="_blank"
        >
            ICP-Brasil Docs
        </Button>
        <YkueDownloadButton />
        <Button
          variant="contained"
          className={classes.button}
          href={env.REACT_APP_OPEN_API_URL}
          target="_blank"
        >
            API Reference&nbsp;
          <Typography variant="caption" className={classes.badge}>
            {env.REACT_APP_API_VERSION}
          </Typography>
        </Button>
      </YbyraFlex>

      <br/>
      <p>
          Ybyrá is an attribute certificate managing service built in accordance to the standards of the Brazilian PKI
          (ICP-Brasil).
          This application aims to manage the entire lifecycle of the certificate from certificate request to
          renovation, revocation or expiration.
      </p>

      <p>
          For authentication and signatures, this service requires ICP-Brasil certificates. To ensure safe communication
          with all forms os certificate storage methods supported by ICP-Brasil, the user is expected to use the
          intermediary application Ykue.
      </p>

      <p>
          This webpage is aimed at developers and is a demonstration of the functionalities already available on the API
          and may not represent the final product.
      </p>

    </YbyraContainer>
  );
}

AllowanceWelcomeComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeComponent);
