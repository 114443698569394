import React from "react";
import {Route} from "react-router-dom";
import ChangeRoleComponent from "./change_role";

const changeRoleRoutes = (
  <React.Fragment>
    <Route path="change-role" element={<ChangeRoleComponent />} />
  </React.Fragment>
)

export default changeRoleRoutes;