import React from "react";
import {useContext} from "react";
import {AlertDataContext, setErrorAlert, setSuccessAlert} from "../../../common/components/alerter";
import {TEMPLATE_SUCCESS} from "../../../common/constants/errors";
import PropTypes from "prop-types";
import Agent from "../../../common/communication/agent";

export default function CreateTemplateButton({templateName,publicTemplate, attributes, validity,
  authorityKeyID,auditIdentity,noRevAvail,acTargetSet,aia,clearFields}) {
  const alertContext = useContext(AlertDataContext);

  function endWithSlash(str) {
    return str.endsWith("/") ? str : str + "/";
  }

  const submitForm = async () => {
    let requestJson = {
      templateName: templateName,
      publicTemplate: publicTemplate,
      templateAttributes: {
        attributesSet: attributes,
        validityInDays: validity,
        authorityKeyIdentifier: authorityKeyID ,
        auditIdentity: auditIdentity,
        noRevAvail: noRevAvail,
        actargetsSet: acTargetSet,
        accessDescriptionsSet: [{
          value: endWithSlash(aia),
          type: "CA"
        }]
      }
    }
    try {
      await Agent.Template.create({}, JSON.stringify(requestJson));
      setSuccessAlert(TEMPLATE_SUCCESS, alertContext);
      clearFields();
    } catch (error) {
      setErrorAlert(error.response.body.message, alertContext);
    }
  }

  const isCreateDisabled = () => {
    return !templateName || !validity || validity <= 0 || !attributes.length || !aia
  }

  return (
    <React.Fragment>
      <button type="button"
        className="btn btn-primary"
        onClick={() => submitForm()}
        disabled={isCreateDisabled()}
      >
          Create
      </button>
    </React.Fragment>
  )
}

CreateTemplateButton.propTypes = {
  templateName: PropTypes.string.isRequired,
  publicTemplate: PropTypes.bool.isRequired,
  attributes: PropTypes.array.isRequired,
  validity: PropTypes.string.isRequired,
  authorityKeyID: PropTypes.bool.isRequired,
  auditIdentity: PropTypes.bool.isRequired,
  noRevAvail: PropTypes.bool.isRequired,
  acTargetSet: PropTypes.array.isRequired,
  aia: PropTypes.string.isRequired,
  clearFields: PropTypes.func.isRequired
}