import {
  UPDATE_FIELD_SIGN,
  SIGN_CONFIG_CREATE_SUCCESSFUL,
  SIGN_CONFIG_CREATE_FAILURE,
  MISSING_SIGN_CONFIG_FIELDS
} from '../features/common/components/certificate_list/action_type';

const defaultState = {
  message: "",
  type: ""
};

const Sign = (state = defaultState, action) => {
  switch (action.type) {
  case UPDATE_FIELD_SIGN:
    return {...state, [action.key]: action.value};
  case SIGN_CONFIG_CREATE_SUCCESSFUL:
  case SIGN_CONFIG_CREATE_FAILURE:
  case MISSING_SIGN_CONFIG_FIELDS:
    return {...state, "message": action.payload};

  default:
    return state;
  }

};

export default Sign;
