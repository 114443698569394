import React from "react";
import CreateTemplateComponent from "./create/create_template";
import TemplateListComponent from "./list/components/template_grid";
import { AttrCertViewEnum, RoleEnum } from "../common/constants/enums";
import { connect } from "react-redux";
import { LOGIN } from "../common/components/certificate_list/action_type";
import { Link, Route, Routes } from "react-router-dom";
import BaseSubNavBarComponent from "../common/components/base_sub_bar";
import PropTypes from "prop-types";

const OperatorUsersView = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <ul className="nav nav-pills">
        <li className="nav-item">
          <Link to="create" className="nav-link">
            Create Template
          </Link>
        </li>
        <li className="nav-item">
          <Link to="list" className="nav-link">
            My Templates
          </Link>
        </li>
      </ul>
    </nav>
  );
};

const AdministratorUsersView = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <ul className="nav nav-pills">
        <li className="nav-item">
          <Link to="" className="nav-link">
            Empty
          </Link>
        </li>
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  ...state.auth,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (payload) => dispatch({ type: LOGIN, payload }),
});

class TemplateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedView: AttrCertViewEnum.NONE,
    };

    this.roleRender = this.roleRender.bind(this);
    this.renderRoutes = this.renderRoutes.bind(this);
  }

  roleRender() {
    switch (this.props.currentUser) {
    case RoleEnum.ADMINISTRATOR:
      return <AdministratorUsersView />;
    case RoleEnum.OPERATOR:
      return <OperatorUsersView />;
    default:
      return <div>Unauthorized</div>;
    }
  }

  renderRoutes() {
    return (
      <Routes>
        <Route path="create" element={<CreateTemplateComponent />} />
        <Route path="list" element={<TemplateListComponent isEditable= {true} />} />
      </Routes>
    );
  }

  render() {
    return (
      <React.Fragment>
        <BaseSubNavBarComponent
          roleRender={this.roleRender}
          renderRoutes={this.renderRoutes}
        />
      </React.Fragment>
    );
  }
}

TemplateComponent.propTypes = {
  currentUser: PropTypes.oneOf(Object.keys(RoleEnum)).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateComponent);
