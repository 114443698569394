import React from "react";
import { SvgIcon, Tooltip } from "@mui/material";

const ErrorIcon = (props) => (
  <Tooltip {...props} arrow>
    <SvgIcon component="svg" height="25" width="25" viewBox="0 0 32 32">
      <circle cx="16" cy="16" r="16" style={{ fill: "#D72828" }} />
      <path
        d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
        style={{ fill: "#E6E6E6" }}
      />
    </SvgIcon>
  </Tooltip>
);

export default ErrorIcon;
