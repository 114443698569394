import * as React from 'react';
import {useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {FormGroup} from "@material-ui/core";
import {AttributeList} from "./attribute_list";
import Agent from "../../../common/communication/agent";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import {AccessDescriptionList} from "./access_description_list";
import {randomId} from "@mui/x-data-grid-generator";
import {AlertDataContext, setErrorAlert} from "../../../common/components/alerter";
import {TEMPLATE_UPDATE_ERROR} from "../../../common/constants/errors";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const TemplateEditModal = ( {open, setOpen, payload, setPayload, templateId} ) => {
  const handleClose = () => setOpen(false);

  const EditButton = ({attributes, accessDescription}) => {
    const alertContext = useContext(AlertDataContext);

    const submitForm = () => {
      let request_params = payload
      request_params.templateParameters.attributesSet = cleanData(attributes);
      request_params.templateParameters.accessDescriptionsSet = cleanData(accessDescription);
      handleClose();
      Agent.Template.update(request_params, request_params.id).catch((error) => {
        setErrorAlert(TEMPLATE_UPDATE_ERROR + error.response.body.message , alertContext);
      });
    }

    const cleanData = (row) => {
      return row.map((el) => {delete el.id; delete el.isNew; return el});
    }

    return(
      <button type="button" className="btn btn-primary"
        onClick={ () => submitForm()}>Save modifications</button>
    )
  }

  const EditTemplate = (templateId) => {
    const [templateAttributes, setTemplateAttributes] = React.useState([]);
    const [accessDescription, setAccessDescription] = React.useState([]);

    const handleChange = (e) => {
      let new_payload = payload;
      new_payload[e.target.name] = e.target.checked
      setPayload(new_payload);
    };

    const handleChangeName = (e) => {
      let new_payload = payload;
      new_payload[e.target.name] = e.target.value
      setPayload(new_payload);
    };

    const handleTemplateParametersChange = (e) => {
      let new_payload = payload;
      new_payload["templateParameters"][e.target.name] = parseInt(e.target.value);
      setPayload(new_payload);
    };

    const handleCheckedTemplateParametersChange = (e) => {
      let new_payload = payload;
      new_payload["templateParameters"][e.target.name] = e.target.checked;
      setPayload(new_payload);
    };

    const listIndexer = (list) => {
      return list.map((el) => ({...el, id: randomId()}));
    }

    useEffect(() => {
      const fetchTemplateAttributes = async () => {
        const response = await Agent.Template.getById(templateId.templateId);
        setTemplateAttributes(listIndexer(response.templateParameters.attributesSet));
        setAccessDescription(listIndexer(response.templateParameters.accessDescriptionsSet));
      }
      if (templateId !== -1) fetchTemplateAttributes();
    }, [templateId])

    if (!payload.id) {
      return;
    }
    return (
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField  variant="standard"
            defaultValue={payload.name} label="Template name" name="templateName" onChange={handleChangeName}/>

          <TextField inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
            name={"validityInDays"} onChange={handleTemplateParametersChange}
            defaultValue={payload.templateParameters.validityInDays} variant="standard" type="number"
            label="Validity in days" />
          <br/>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <AccessDescriptionList rows={accessDescription} setRows={setAccessDescription}/>
            </Grid>
            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel control={
                  <Checkbox name="publicTemplate" defaultChecked={payload.publicTemplate}
                    onChange={handleChange}
                  />}
                label="Public" />
                <FormControlLabel
                  control={
                    <Checkbox name="auditIdentity" defaultChecked={payload.templateParameters.auditIdentity}
                      onChange={handleCheckedTemplateParametersChange}
                    />}
                  label="Audit Identity" />
                <FormControlLabel  control={
                  <Checkbox name="noRevAvail" defaultChecked={payload.templateParameters.noRevAvail}
                    onChange={handleCheckedTemplateParametersChange}
                  />}
                label="noRevAvail" />
                <FormControlLabel control={<Checkbox name="authorityKeyIdentifier"
                  defaultChecked={payload.templateParameters.authorityKeyIdentifier}
                  onChange={handleCheckedTemplateParametersChange}
                />}
                label="Authority Key Identifier" />
              </FormGroup>
            </Grid>
          </Grid>
          <br/>
          <AttributeList rows={templateAttributes} setRows={setTemplateAttributes}/>
          <br/>
        </div>
        <EditButton attributes={templateAttributes} accessDescription={accessDescription}/>
      </Box>
    );
  }
  EditTemplate.propTypes = {
    templateId: PropTypes.string.isRequired,
    accessDescription: PropTypes.array.isRequired,
    attributes: PropTypes.array.isRequired
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Template {payload.name}
          </Typography>
          <EditTemplate templateId={templateId}/>
        </Box>
      </Modal>
    </div>
  );
}



TemplateEditModal.propTypes = {
  templateId: PropTypes.string.isRequired,
  accessDescription: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
};
export default TemplateEditModal;