import React, {useContext} from 'react';

import {connect} from 'react-redux';
import {LOGIN, UPDATE_FIELD_AUTH} from "../common/components/certificate_list/action_type";
import Agent from '../common/communication/agent';
import WelcomeComponent from "../welcome/welcome";
import {AlertDataContext, setErrorAlert, setSuccessAlert} from "../common/components/alerter";
import PropTypes from "prop-types";
import {AUTH_SUCCESS} from "../common/constants/errors";
import {withTheme} from "@material-ui/styles";
import {YbyraContainer} from "../common/style";

const mapStateToProps = state => ({
  ...state.auth,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({
  onChangeYkueUserIdentifier: value =>
    dispatch({type: UPDATE_FIELD_AUTH, key: 'ykueUserIdentifier', value}),
  onSubmit: (payload) =>
    dispatch({type: LOGIN, payload})
})

const AuthenticationComponent = (props) => {
  return (<AllowanceAuthenticationComponent {...props} />);
};


class AllowanceAuthenticationComponent extends React.Component {

  constructor(props) {
    super(props);
  }



  render() {
    const ykueUserIdentifier = this.props.ykueUserIdentifier;
    if (this.props.currentUser === "NONE") {
      return (
        <YbyraContainer>
          <h2>Login</h2>

          <form>

            <div className="mb-3">
              <label htmlFor="ykue-user-identifier" className="form-label">Ykue User Identifier</label>
              <input
                className="form-control"
                id="ykue-user-identifier"
                name="ykue-user-identifier"
                value={ykueUserIdentifier}
                onChange={(event) => {
                  this.props.onChangeYkueUserIdentifier(event.target.value)
                }}
              />
            </div>
            <LoginButton env={this}/>
          </form>
        </YbyraContainer>
      );
    }
    else {
      return (<WelcomeComponent/>);
    }
  }
}

AllowanceAuthenticationComponent.propTypes = {
  onChangeYkueUserIdentifier: PropTypes.func.isRequired,
  currentUser: PropTypes.string.isRequired,
  ykueUserIdentifier: PropTypes.string.isRequired
}

const LoginButton = ({env}) => {
  const alertContext = useContext(AlertDataContext);
  const submitForm = () => {
    Agent.Auth.login(
      env.props.ykueUserIdentifier
    ).then((response => {
      setSuccessAlert(AUTH_SUCCESS, alertContext);
      env.props.onSubmit({
        token: response.accessToken,
        role: response.userRole,
        refreshToken: response.refreshToken
      })
    })).catch((error) => {
      setErrorAlert(error.response.body.message, alertContext);
    });
  }

  return(
    <button type="button" className="btn btn-primary" disabled={(env.props.ykueUserIdentifier == null ||
        env.props.ykueUserIdentifier === "")} onClick={ () => submitForm()}>Log in</button>
  )
}

LoginButton.propTypes = {
  env: PropTypes.any.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(AuthenticationComponent));

