import {Route} from "react-router-dom";
import React from "react";
import CertviewComponent from "../common/components/certificate_list/certificate_lister";

const certificateRoutes = (
  <React.Fragment>
    <Route path="certificates/list" element={<CertviewComponent type="download"/>} />
  </React.Fragment>
)

export default certificateRoutes;
