import React, {useContext} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import Agent from "../common/communication/agent";
import {
  LOGIN, SET_MESSAGE,
  UPDATE_AVAILABLE_ROLES,
  UPDATE_ROLE_TO_CHANGE,
} from "../common/components/certificate_list/action_type";
import {Link} from "react-router-dom";
import {AlertDataContext, setErrorAlert, setSuccessAlert} from "../common/components/alerter";
import PropTypes from "prop-types";
import {CHANGE_ROLE_SUCCESS} from "../common/constants/errors";
import {YbyraContainer} from "../common/style";

const ChangeRoleComponent = (props) => {
  return <AllowanceAboutComponent {...props} />;
};

const mapStateToProps = (state) => {
  return {
    appName: state.common.appName,
    availableRoles: state.common.availableRoles,
    roleToChange: state.common.roleToChange,
    message: state.common.message
  };
};

const mapDispatchToProps = (dispatch) => ({
  onListAvailableRoles: (payload) =>
    dispatch({ type: UPDATE_AVAILABLE_ROLES, payload }),
  onUpdateRoleToChange: (value) =>
    dispatch({ type: UPDATE_ROLE_TO_CHANGE, value }),
  //using the same dispatch type as LOGIN because it changes the same states
  onSubmit: (payload) => {
    dispatch({type: LOGIN, payload})
  },
  onChangeMessage: (value) => {
    dispatch({type: SET_MESSAGE, value})
  }
});

class AllowanceAboutComponent extends React.Component {
  constructor(props) {
    super(props);
    this.updateRoleToChange = (ev) => this.props.onUpdateRoleToChange(ev.target.value);
    this.availableRoles = (roles) =>
      this.props.onListAvailableRoles(roles);
    this.changeMessage = (message) => this.props.onChangeMessage(message);
    this.succesfullMessage = CHANGE_ROLE_SUCCESS;
  }
  renderMessage() {
    if (this.props.message) return <div>{this.props.message}</div>;
  }
  componentDidMount() {
    this.updateAvailableRoles()
  }

  async updateAvailableRoles() {
    try {
      this.changeMessage("")
      let response = await Agent.User.listUserRoles()
      this.availableRoles(response.userRole);
      this.props.onUpdateRoleToChange(response.userRole[0])
    }
    catch {
      this.availableRoles([])
      this.changeMessage("We had a problem. Roles are unavailable.");
    }
  }
  render() {
    const availableRoles = this.props.availableRoles;
    const roleSuccessfullyChanged = this.props.message === CHANGE_ROLE_SUCCESS
    return (
      <YbyraContainer>
        <h2 id="change-role">Change Role</h2>

        <div className="mb-3">
          <label htmlFor="change-role" className="form-label">
          </label>
          {availableRoles.map((role, index) => (
            <div className="form-check" key={index}>
              <input
                className="form-check-input"
                type="radio"
                name="userRole"
                id={role.concat(index)}
                value={role}
                defaultChecked={index === 0}
                onChange={this.updateRoleToChange}
              />
              <label className="form-check-label" htmlFor={role.concat(index)}>
                {role}
              </label>
            </div>
          ))}
          {!roleSuccessfullyChanged && <ChangeRoleButton env={this}/>}
        </div>
        {this.renderMessage()}
        {roleSuccessfullyChanged &&
            <Link to="/">
              <button type="button" className="btn btn-primary mt-lg-1">
                Go Home
              </button>
            </Link>
        }
      </YbyraContainer>
    );
  }
}

AllowanceAboutComponent.propTypes = {
  message: PropTypes.string.isRequired,
  availableRoles: PropTypes.array.isRequired,
  onUpdateRoleToChange: PropTypes.func.isRequired,
  onListAvailableRoles: PropTypes.func.isRequired,
  onChangeMessage: PropTypes.func.isRequired
}

const ChangeRoleButton = ({env}) => {
  const alertContext = useContext(AlertDataContext);
  const submitForm = async () => {
    Agent.User.changeRole(
      env.props.roleToChange
    ).then((response => {
      setSuccessAlert(env.succesfullMessage, alertContext);
      env.props.onSubmit({
        token: response.accessToken,
        refreshToken: response.refreshToken,
        role: response.userRole
      },
      )
    })).catch((error) => {
      setErrorAlert(error.response.body.message, alertContext);
    });
  }

  return(
    <button
      type="button"
      className="btn btn-primary mt-lg-1"
      onClick={ () => submitForm()}
    >
          Submit
    </button>
  )
}

ChangeRoleButton.propTypes = {
  env: PropTypes.any.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRoleComponent);
