import PropTypes from "prop-types";
import React, {useContext, useEffect} from "react";
import {AlertDataContext, setErrorAlert} from "../../../common/components/alerter";
import {RETRIEVE_MODIFIABLE_ATTRIBUTES_ERROR} from "../../../common/constants/errors";
import Agent from "../../../common/communication/agent";


const ModifiableAttributeComponent = ({templateId, oidMap}) => {

  const [template, setTemplate] = React.useState(null);
  const alertContext = useContext(AlertDataContext);

  useEffect(() => {
    const updateTemplate = async () => {
      if (templateId !== -1) {
        await Agent.Template.getById(templateId)
          .then((temp) => {
            oidMap.clear();
            setTemplate(temp);
          }).catch(() => {
            setErrorAlert(RETRIEVE_MODIFIABLE_ATTRIBUTES_ERROR, alertContext);
          });
      }
    }
    updateTemplate();
  }, [templateId]);

  if (template == null) return null;
  let modifiableAttributes = [];
  let i = 0;
  template.templateParameters.attributesSet.forEach((attribute) => {
    if (attribute.modifiable) {
      modifiableAttributes.push(<div key={template.id + "-" + i}>
        <div className="mb-3">
          <label htmlFor={"modifiable-attribute-" + i}
            className="form-label">{attribute.oid} ({attribute.description}):</label>
          <input className="form-control" type="text" id="issue-certificate-dn"
            defaultValue={attribute.value}
            name={"modifiable-attribute-" + i + "-value"}
            onChange={(ev) => {
              oidMap.set(attribute.oid, ev.target.value);
            }}/>
        </div>
      </div>);
    }
    i++;
  });
  if (modifiableAttributes.length === 0)
    return null;

  return (<div>
    <h4>Modifiable Attributes:</h4>
    {modifiableAttributes}
  </div>);

}

ModifiableAttributeComponent.propTypes = {
  templateId: PropTypes.number,
  oidMap: PropTypes.object
};

export default ModifiableAttributeComponent;
