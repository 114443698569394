import {Route} from "react-router-dom";
import React from "react";
import WelcomeComponent from "./welcome";

const welcomeRoutes = (
  <React.Fragment>
    <Route index element={<WelcomeComponent/>} />
  </React.Fragment>
)


export default welcomeRoutes;
