import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";

export default function TemplateHeader({templateName, onChangeTemplateName,
  validity, setValidity,revocation,toggleRevocable,aia,
  setAia, publicTemplate, changePublicTemplate}){

  function verifyValidity(validity){
    const re = /^[0-9\b]+$/;
    if (re.test(validity) || validity === "") {
      return true;
    }
  }

  function handleValidityChange(e,setValidity){
    if(verifyValidity(e.target.value)){
      setValidity(e.target.value);
    }
  }

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            size="small"
            required
            id="template-name"
            name="template-name"
            label="Template Name"
            fullWidth
            autoComplete="template-name"
            value={templateName}
            onChange={(e) => onChangeTemplateName(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            required
            type="text"
            id="validity"
            name="validity"
            label="Validity (days)"
            value={validity}
            autoComplete="template-name"
            onChange={(e) => handleValidityChange(e, setValidity)}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel style={{paddingTop: '12px'}}
            control={
              <Checkbox
                onChange={changePublicTemplate}
                name="public-template"
                color="primary"
                checked={publicTemplate}
              />
            }
            label="Public Template"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            size="small"
            required
            type="text"
            id="access-description"
            name="access-description"
            label="Access Description Base URI"
            helperText="Modify this field if you want to publish your signer certificate on your own server"
            fullWidth
            value={aia}
            autoComplete="template-name"
            onChange={(e) => setAia(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel style={{paddingTop: '12px'}}
            control={
              <Checkbox
                onChange={toggleRevocable}
                name="norevavail"
                color="primary"
                defaultChecked={revocation}
              />
            }
            label="Revocable"
          />
        </Grid>
      </Grid>
    </form>
  );
}

TemplateHeader.propTypes = {
  templateName: PropTypes.string.isRequired,
  onChangeTemplateName: PropTypes.func.isRequired,
  acessDescription: PropTypes.array.isRequired,
  setAcessDescription: PropTypes.func.isRequired,
  validity: PropTypes.string.isRequired,
  setValidity: PropTypes.func.isRequired,
  revocation: PropTypes.bool.isRequired,
  toggleRevocable: PropTypes.func.isRequired,
  aia: PropTypes.string.isRequired,
  setAia: PropTypes.func.isRequired,
  publicTemplate: PropTypes.bool.isRequired,
  changePublicTemplate: PropTypes.func.isRequired,
}
