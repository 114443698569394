import React from "react";
import { createRoot } from 'react-dom/client';
import "./styles/index.css";
import { Provider } from "react-redux";
import { store } from "./store";
import App from "./app";
import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";

const themeLight = createTheme({
  palette: {
    background: {
      default: "#fdfffc",
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MuiThemeProvider theme={themeLight}>
        <App />
      </MuiThemeProvider>
    </BrowserRouter>
  </Provider>
);
