import React, {useContext} from "react";
import Agent from "../../common/communication/agent";
import {saveAs} from 'file-saver';
import ModifiableAttributeComponent from "./components/attributes_form";
import {AlertDataContext, handleError, setSuccessAlert} from "../../common/components/alerter";
import {ATTRIBUTE_CERTIFICATE_BEGIN_HEADER, ATTRIBUTE_CERTIFICATE_END_HEADER}
  from "../../common/constants/string_defaults";
import {ISSUE_SUCCESS} from "../../common/constants/errors";
import TemplateGrid from "../../template/list/components/template_grid";
import {YbyraContainer} from "../../common/style";

const IssueCertificateByDnComponent = () => {

  const [oidToValue] = React.useState(new Map());
  const [distinguishedName, setDistinguishedName] = React.useState("");
  const [templateId, setTemplateId] = React.useState(-1);
  const [ykueId, setYkueId] = React.useState("");
  const [issuedCertificate, setIssuedCertificate] = React.useState("");
  const alertContext = useContext(AlertDataContext);

  const issueCertificate = () => {
    Agent.Certificate.issue_by_csv({
      templateId: templateId,
      csvFileText: buildCsv(),
      ykueId: ykueId
    })
      .then((response) => {
        setIssuedCertificate(response[0].pem);
        setSuccessAlert(ISSUE_SUCCESS, alertContext);
      }).catch(error => {
        handleError(error, alertContext)
      })
  }

  const buildCsv = () => {
    const distinguishedNameList = distinguishedName.split(',');
    let keysString = "";
    let valuesString = "";
    distinguishedNameList.forEach((pair) => {
      const [key, value] = pair.split('=');
      keysString += key.toLowerCase() + ",";
      valuesString += value  + ",";
    });
    oidToValue.forEach((value, key) => {
      keysString += key + ",";
      valuesString += value + ",";
    });
    return keysString.slice(0, -1) + "\n" + valuesString.slice(0, -1);
  }

  const canIssue = ({templateId, ykueId, distinguishedName}) => {
    return templateId !== -1 && ykueId.trim() !== "" && distinguishedName.trim() !== "";
  }

  const renderButtons = () => {
    return (
      <div
        className="d-flex justify-content-between"
      >
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => issueCertificate()}
          disabled={!canIssue({templateId, ykueId, distinguishedName})}
        >Issue
        </button>
        {renderDownloadButton()}
      </div>
    );
  }

  const downloadCsv = () => {
    const cert = ATTRIBUTE_CERTIFICATE_BEGIN_HEADER + issuedCertificate + ATTRIBUTE_CERTIFICATE_END_HEADER;
    const file = new File([cert], "certificate.pem", {type: "application/x-x509-user-cert"});
    saveAs(file);
  }
  const renderDownloadButton = () => {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => downloadCsv()}
          disabled={issuedCertificate === ""}
        >Download
        </button>
      </>
    );

  }

  return (
    <YbyraContainer>
      <h2>Issue Certificates by Distinguished Name</h2>
      <form>
        <div>
          <TemplateGrid isEditable={false} setParentTemplateId={setTemplateId} />
          <p></p>
          <div className="mb-3">
            <label htmlFor="issue-certificate-dn" className="form-label">
                Distinguished Name:{" "}
            </label>
            <input
              className="form-control"
              type="text"
              id="issue-certificate-dn"
              name="csvFileText"
              placeholder="CN=Aristoteles Ferreira,O=UFSC,OU=LabSEC,C=BR"
              onChange={(e) => setDistinguishedName(e.target.value)}
            />
            <p></p>
            <ModifiableAttributeComponent
              templateId={templateId}
              oidMap={oidToValue}
            />
            <label className="form-label">
                Ykue Identifier:{" "}
            </label>
            <input
              className="form-control"
              type="text"
              id="ykueId"
              name="ykueId"
              onChange={(e) => setYkueId(e.target.value)}
              value={ykueId}
            />
          </div>
        </div>
        {renderButtons()}
      </form>
    </YbyraContainer>
  );

}
export default IssueCertificateByDnComponent;
