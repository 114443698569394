import React from "react";
import {Route} from "react-router-dom";
import AdmComponent from "./adm";

const admRoutes = (
  <React.Fragment>
    <Route path="adm" element={<AdmComponent />}/>
  </React.Fragment>
)

export default admRoutes;