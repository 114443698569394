import React, {useContext} from "react";
import Agent from "../../common/communication/agent";
import {
  UPDATE_FIELD_USERS,
  USER_CREATE_SUCCESSFUL,
} from "../../common/components/certificate_list/action_type";
import { connect } from "react-redux";
import { RoleEnum } from "../../common/constants/enums";
import {AlertDataContext, setErrorAlert, setSuccessAlert} from "../../common/components/alerter";
import PropTypes from "prop-types";
import {USER_SUCCESS} from "../../common/constants/errors";
import {verifyCpfCnpj} from "../../common/functions/cpf_cnpj";
import {YbyraContainer} from "../../common/style";

const mapStateToProps = (state) => ({
  ...state.auth,
  cpfCnpj: state.users.cpfCnpj,
  userRole: state.users.userRole,
  message: state.users.message,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeCpfCnpj: (value) =>
    dispatch({ type: UPDATE_FIELD_USERS, key: "cpfCnpj", value }),
  onChangeRole: (value) =>
    dispatch({ type: UPDATE_FIELD_USERS, key: "userRole", value }),
  onChangeMessage: (value) =>
    dispatch({ type: UPDATE_FIELD_USERS, key: "message", value }),
  onSubmit: (payload) => dispatch({ type: USER_CREATE_SUCCESSFUL, payload }),
});

class CreateUserFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.formData = {
      userRole: "OPERATOR",
    };

    this.changeCpfCnpj = (ev) => this.props.onChangeCpfCnpj(ev.target.value);
    this.changeRole = (ev) => this.props.onChangeRole(ev.target.value);
    this.changeMessage = (message) => this.props.onChangeMessage(message);

    this.handleFileChange = this.handleFileChange.bind(this);
  }

  renderMessage() {
    if (this.props.message) return <div>{this.props.message}</div>;
    else return;
  }

  handleFileChange(file) {
    if (!file) {
      this.formData.filePem = "";
      return;
    }
    let fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.changeChain(fileReader.result);
    };
    fileReader.readAsText(file);
  }

  handleTextChange(text) {
    this.formData.textPem = text;
  }

  render() {
    const cpfCnpj = this.props.cpfCnpj;
    return (
      <YbyraContainer>
        <h2>Register New User</h2>

        <form>
          <label>
              Insert the user&apos;s CPF or CNPJ.
          </label>
          <div className="mb-3">
            <label htmlFor="ykue-user-identifier" className="form-label">CPF or CNPJ</label>
            <input
              className="form-control"
              id="cpf-cnpj"
              name="cpf-cnpj"
              value={cpfCnpj}
              onChange={(event) => {
                this.props.onChangeCpfCnpj(event.target.value)
              }}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="userRole" className="form-label">
              User role:
            </label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="userRole"
                id="userRole1"
                defaultChecked
                value={RoleEnum.ADMINISTRATOR}
                onChange={this.changeRole}
              />
              <label className="form-check-label" htmlFor="userRole1">
                  ADMINISTRATOR
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="userRole"
                id="userRole2"
                value={RoleEnum.OPERATOR}
                onChange={this.changeRole}
              />
              <label className="form-check-label" htmlFor="userRole2">
                  OPERATOR
              </label>
            </div>
          </div>
          <ExportButton env={this}/>
        </form>
        {this.renderMessage()}
      </YbyraContainer>
    );
  }
}

CreateUserFormComponent.propTypes = {
  cpfCnpj: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onChangeCpfCnpj: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  onChangeMessage: PropTypes.func.isRequired
}

const ExportButton = ({env}) => {
  const alertContext = useContext(AlertDataContext);
  const submitForm = () => {
    let requestJson = {
      cpfCnpj: env.props.cpfCnpj,
      userRole: env.props.userRole,
    };
    Agent.User.create(requestJson)
      .then(() => {
        setSuccessAlert(USER_SUCCESS, alertContext);
      })
      .catch((error) => {
        setErrorAlert(error.response.body.message, alertContext);
      });
  }

  return(
    <button type="button" className="btn btn-primary" disabled={!verifyCpfCnpj(env.props.cpfCnpj)}
      onClick={ () => submitForm()}>Register</button>
  )
}

ExportButton.propTypes = {
  env: PropTypes.any.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserFormComponent);
