export const RoleEnum = Object.freeze({
  ADMINISTRATOR: "ADMINISTRATOR",
  OPERATOR: "OPERATOR",
  NONE: "NONE"
});

export const AppViewEnum = Object.freeze({
  DATABASE: 1,
  CONFIGURATION: 2,
  LOGIN: 3,
  SERVICES: 4,
  NONE: 5
});

export const ServerStatusEnum = Object.freeze({
  UNCONFIGURED: "UNCONFIGURED",
  UNLOCKED: "UNLOCKED",
  OFFLINE: "OFFLINE"
});

export const UsersComponentEnum = Object.freeze({
  CREATE: 1,
  CERT_CHAIN: 2,
  REGISTER_SIGNING: 3,
  CONFIGURE_SIGNING: 4,
  MANAGE_USERS: 5,
  NONE: 6
});

export const ServiceViewEnum = Object.freeze({
  CERTIFICATION_AUTHORITY: 1,
  ISSUING_ENTITY: 2,
  ATTRIBUTE_CERTIFICATE: 3,
  CERTIFICATE_REVOCATION: 4,
  USERS: 5,
  TEMPLATE: 6,
  ISSUE: 7,
  LOGIN: 8,
  HOME: 9,
  NONE: 10
});

export const TemplateViewEnum = Object.freeze({
  TEMPLATE: 1,
  TEMPLATE_AUTH: 2,
  TEMPLATE_LIST: 3,
  NONE: 4
});

export const AttrCertViewEnum = Object.freeze({
  CERTIFICATE: 1,
  CSV: 2,
  NONE: 3
});
