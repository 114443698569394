import {lighten, makeStyles} from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { lightGreen, red, blue } from "@material-ui/core/colors";



export const mainStyle = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
    backgroundColor: "rgba(253, 255, 252, 0.98)",
    minHeight: "",
  },
  subBar: {
    backgroundColor: "rgb(250,250,250)",
  },
  badge: {
    backgroundColor: '#808080', // Gray background color
    color: '#ffffff', // White text color
    borderRadius: theme.spacing(3), // Rounded corners for pill shape
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export const NormalButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(lightGreen[200]),
    backgroundColor: lightGreen[100],
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: lightGreen[200],
      color: theme.palette.getContrastText(lightGreen[200]),
    },
  },
}))(Button);

export const ConfirmButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[900]),
    backgroundColor: blue[600],
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: blue[900],
      color: theme.palette.getContrastText(blue[900]),
    },
  },
}))(Button);

export const CancelButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[900]),
    backgroundColor: red[400],
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: red[900],
      color: theme.palette.getContrastText(red[900]),
    },
  },
}))(Button);


export const aboutStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    backgroundColor: "rgba(253, 255, 252, 0.98)",
  },
  cont: {
    width: "90%",
    height: "90",
  },
}));

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light' ? {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    } : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark,
    },
  title: {
    flex: '1 1 100%',
  },
}));

export const extensionTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export const extensionFormStyles = makeStyles(() => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {},
}));

export const certificateTableToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingjustify: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: "white",
        backgroundColor: "dodgerblue",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

export const certificateEnhancedTableStyles = makeStyles(() => ({
  selected: {
    backgroundColor: "LightSkyBlue !important",
  },
}));

export const CertificateListLoaderStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingBlock: theme.spacing(2),
  },
  paper: {
    width: '100%',
  },
}));

export const ybyraContainerStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    padding: "20%",
    [theme.breakpoints.between('xs', 'md')]: {
      padding: "10%",
      paddingTop: "12px",
    },
    paddingTop: "24px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column"
  },
  background: {
    backgroundColor: "#f9f9f9"
  },
  button: {
    margin: 'px',
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkgreen',
    },
    height: ''
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  flex: {
    display: 'flex',
    gap: '10px',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexDirection: "column"
    },
  }
}));
