import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {connect} from "react-redux";
import {Paper} from "@material-ui/core";
import {Link} from "react-router-dom";

const PageNotFound = () => {
  return (<Paper className={"makeStyles-paper-4 d-flex flex-column align-items-center"} >

    <h2 id="not-found" className="text-center">The page your are looking for does not exist</h2>
    <Link to="/">
      <button className={"btn btn-primary btn-lg align-items-center"}>Go Home</button>
    </Link>

  </Paper>);
};

const mapStateToProps = state => {
  return {
    appName: state.common.appName
  }
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);
