export const LOGIN_PAGE_LOADED = 'LOGIN_LAGE_LOADED';
export const SYSTEM_UNLOCK = 'SYSTEM_UNLOCK';
export const SYSTEM_UNLOCK_FAILURE = 'SYSTEM_UNLOCK_FAILURE';
export const SYSTEM_DELETE = 'SYSTEM_DELETE';
export const SYSTEM_CONFIG = 'SYSTEM_CONFIG';
export const DEV_MODE_CHANGE = 'DEV_MODE_CHANGE';


export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const ARTICLE_SUBMITTED = 'ARTICLE_SUBMITTED';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const TRANSACTION_UPDATE = 'TRANSACTION_UPDATE';
export const ARTICLE_PAGE_LOADED = 'ARTICLE_PAGE_LOADED';
export const ARTICLE_PAGE_UNLOADED = 'ARTICLE_PAGE_UNLOADED';
export const SET_PAGE = 'SET_PAGE';
export const SET_PAGE_TRANSACTION = 'SET_PAGE_TRANSACTION';
export const CHANGE_TAB = 'CHANGE_TAB';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED';
export const EDITOR_PAGE_UNLOADED = 'EDITOR_PAGE_UNLOADED';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const PROFILE_FAVORITES_PAGE_UNLOADED = 'PROFILE_FAVORITES_PAGE_UNLOADED';
export const PROFILE_FAVORITES_PAGE_LOADED = 'PROFILE_FAVORITES_PAGE_LOADED';
export const USER_CREATE_LOAD = 'USER_CREATE_LOAD';
export const USER_CREATE_SUCCESSFUL = 'USER_CREATE_SUCCESSFUL';
export const USER_CREATE_UNSUCCESSFUL = 'USER_CREATE_UNSUCCESSFUL';
export const UPDATE_FIELD_USERS = 'UPDATE_FIELD_USERS';
export const UPDATE_FIELD_TEMPLATE = 'UPDATE_FIELD_TEMPLATE';
export const TEMPLATE_CREATE_LOAD = 'TEMPLATE_CREATE_LOAD';
export const TEMPLATE_CREATE_SUCCESSFUL = 'TEMPLATE_CREATE_SUCCESSFUL';
export const TEMPLATE_CREATE_UNSUCCESSFUL = 'TEMPLATE_CREATE_UNSUCCESSFUL';
export const REMOVE_ATTRIBUTE_TEMPLATE = 'REMOVE_ATTRIBUTE_TEMPLATE';
export const ADD_TEMPLATE_ATTRIBUTE = 'ADD_TEMPLATE_ATTRIBUTE';
export const CHANGE_ADVANCED_OPTIONS_VISIBILITY = 'CHANGE_ADVANCED_OPTIONS_VISIBILITY';

export const UPDATE_FIELD_SIGN = 'UPDATE_FIELD_SIGN';
export const SIGN_CONFIG_CREATE_SUCCESSFUL = 'SIGN_CONFIG_CREATE_SUCCESSFUL';
export const SIGN_CONFIG_CREATE_FAILURE = 'SIGN_CONFIG_CREATE_FAILURE';
export const MISSING_SIGN_CONFIG_FIELDS = 'MISSING_SIGN_CONFIG_FIELDS';
export const UPDATE_YKUE_ID = 'YKUE_ID';
export const UPDATE_DN = 'UPDATE_DN';
export const UPDATE_ISSUED_CERTIFICATE = 'UPDATE_ISSUED_CERTIFICATE';
export const UPDATE_ISSUED_CERTIFICATE_LIST = 'UPDATE_ISSUED_CERTIFICATE_LIST';
export const UPDATE_TEMPLATE_LIST = 'UPDATE_TEMPLATE_LIST';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE'
export const UPDATE_CERTIFICATE_VALUE = 'UPDATE_CERTIFICATE_VALUE';
export const ADD_ATTRIBUTE_OIDVALUE_PAIR = 'ADD_ATTRIBUTE_OIDVALUE_PAIR'
export const RESET_CERTIFICATE_ISSUING_STATE = 'RESET_CERTIFICATE_ISSUING_STATE';

export const GET_CRL = 'GET_CRL';
export const UPDATE_ISSUER = 'UPDATE_ISSUER';

export const SET_TEMPLATE_LIST = 'SET_TEMPLATE_LIST';

export const ADD_TEMPLATE_AUTH_FIELD = 'ADD_TEMPLATE_AUTH_FIELD';
export const ADD_TEMPLATE_AUTH_VALUE = 'ADD_TEMPLATE_AUTH_VALUE';
export const SET_TEMPLATE_AUTH_VALUE = 'SET_TEMPLATE_AUTH_VALUE';
export const SET_TEMPLATE_AUTH_OID = 'SET_TEMPLATE_AUTH_OID';
export const SET_TEMPLATE_AUTH_MESSAGE = 'SET_TEMPLATE_AUTH_MESSAGE';

export const SET_MESSAGE = 'SET_MESSAGE';
export const BUTTON_CLICK = 'BUTTON_CLICK';
export const TOGGLE_DEVELOPER_MODE = 'TOGGLE_DEVELOPER_MODE';
export const UPDATE_AVAILABLE_ROLES = 'UPDATE_AVAILABLE_ROLES';
export const UPDATE_ROLE_TO_CHANGE = 'UPDATE_ROLE_TO_CHANGE';

