import React from "react";
import PropTypes from "prop-types";

const RevokeForm = ({setMessage, setCode}) => {
  const handleChangeMessage = event => {
    setMessage(event.target.value);
  };

  const handleChangeReasonCode = event => {
    setCode(event.target.value);
  };

  return (
    <div>
      <p></p>
      <label htmlFor="issue-certificate-dn" className="form-label">
        Revocation Reason:{" "}
      </label>
      <input
        className="form-control"
        type="text"
        onChange={handleChangeMessage}
      />
      <p></p>
      <label htmlFor="issue-certificate-dn" className="form-label">
        Reason Code
      </label>
      <select         className="form-select" onChange={handleChangeReasonCode}>
        <option value="0"> 0 - Unspecified</option>
        <option value="1"> 1 - keyCompromise</option>
        <option value="2"> 2 - cACompromise</option>
        <option value="3"> 3 - affiliationChanged</option>
        <option value="4"> 4 - superseded</option>
        <option value="5"> 5 - cessationOfOperation</option>
        <option value="6"> 6 - certificateHold</option>
        <option value="8"> 8 - removeFromCRL</option>
        <option value="9"> 9 - privilegeWithdrawn</option>
        <option value="10"> 10 - aACompromise</option>

      </select>
      <p></p>
    </div>
  )
}
RevokeForm.propTypes = {
  setMessage: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired
}

export default RevokeForm;