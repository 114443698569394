import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { saveAs } from "file-saver";
import BatchCertificateButton from "./batch_button";
import {
  certificateEnhancedTableStyles,
  certificateTableToolbarStyles,
} from "../../../../styles/base-css";
import { Tooltip } from "@mui/material";
import ErrorIcon from "../../../../styles/error_icon";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", numeric: false, disablePadding: true, label: "Serial Number" },
  { id: "validity", numeric: false, disablePadding: false, label: "Validity" },
  { id: "pem", numeric: false, disablePadding: false, label: "PEM" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelectedInPage,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={
              numSelectedInPage > 0 && numSelectedInPage < rowCount
            }
            checked={rowCount > 0 && numSelectedInPage === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            color="primary"
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "justify"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelectedInPage: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = ({
  numSelected,
  selected,
  certificates,
  type,
  setCertErrors,
  message,
  code,
}) => {
  const classes = certificateTableToolbarStyles();
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 && (
        <BatchCertificateButton
          type={type}
          certificates={certificates}
          selected={selected}
          setCertErrors={setCertErrors}
          message={message}
          code={code}
        ></BatchCertificateButton>
      )}

      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
          style={{ padding: "0em 1em" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Certificates
        </Typography>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
  setCertErrors: PropTypes.func.isRequired,
  certificates: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  code: PropTypes.number.isRequired,
};

export default function CertificateEnhancedTable({
  certificates,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  type,
  message,
  code,
  rowsPerPageOptions,
  totalElements,
}) {
  const serialNumbersInPage = certificates.map((cert) => cert.serialNumber);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("asc");
  const [selected, setSelected] = React.useState([]);
  const [certErrors, setCertErrors] = React.useState({});
  const [dense] = React.useState(false);
  const classes = certificateEnhancedTableStyles();

  React.useEffect(() => setCertErrors({}), [type]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected((prevSelected) => [
        ...new Set(prevSelected.concat(serialNumbersInPage)),
      ]);
      return;
    }
    setSelected((prevSelected) =>
      prevSelected.filter((el) => !serialNumbersInPage.includes(el))
    );
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadCert = (serialNumber, pem) => {
    saveAs(getCertFile(serialNumber, pem));
  };

  const getCertFile = (serialNumber, pem) => {
    return new File([pem], "certificate" + serialNumber + ".crt", {
      type: "application/pkix-cert",
    });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isError = (serialNumber) =>
    Object.prototype.hasOwnProperty.call(certErrors, serialNumber);

  const numSelectedInPage = certificates.reduce(
    (acc, cert) => (selected.includes(cert.serialNumber) ? acc + 1 : acc),
    0
  );

  return (
    <div>
      <EnhancedTableToolbar
        numSelected={selected.length}
        certificates={certificates}
        selected={selected}
        setCertErrors={setCertErrors}
        type={type}
        message={message}
        code={code}
      />
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelectedInPage={numSelectedInPage}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={certificates.length}
          />
          <TableBody>
            {stableSort(certificates, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.serialNumber);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.serialNumber}
                    selected={isItemSelected}
                    classes={{
                      selected: classes.selected,
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        iconStyle={{ fill: "white" }}
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        color="primary"
                        onClick={(event) =>
                          handleClick(event, row.serialNumber)
                        }
                      />
                    </TableCell>
                    <TableCell
                      align="justify"
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      onClick={(event) => handleClick(event, row.serialNumber)}
                    >
                      {row.serialNumber}
                    </TableCell>
                    <TableCell
                      align="justify"
                      onClick={(event) => handleClick(event, row.serialNumber)}
                    >
                      {row.validity}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Download">
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            downloadCert(row.serialNumber, row.pem)
                          }
                        >
                          Download
                        </button>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {isError(row.serialNumber) && (
                        <ErrorIcon title={certErrors[row.serialNumber]} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
}

CertificateEnhancedTable.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  totalElements: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  code: PropTypes.number.isRequired,
  certificates: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
};
