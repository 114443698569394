import {
  UPDATE_DN,
  UPDATE_ISSUED_CERTIFICATE,
  UPDATE_ISSUED_CERTIFICATE_LIST,
  UPDATE_TEMPLATE_LIST,
  UPDATE_TEMPLATE,
  UPDATE_YKUE_ID,
  ADD_ATTRIBUTE_OIDVALUE_PAIR,
  UPDATE_CERTIFICATE_VALUE,
  RESET_CERTIFICATE_ISSUING_STATE
} from '../features/common/components/certificate_list/action_type';

const defaultState = {
  issued_certificate: null,
  issued_certificates: [],
  templates: [],
  attributeOidToValue: {},
  message: "",
  ykueId: ""
};

const Certificate = (state = defaultState, action) => {
  switch (action.type) {
  case ADD_ATTRIBUTE_OIDVALUE_PAIR: {
    let next_state = state;
    next_state.attributeOidToValue[action.oid] = action.value;
    return next_state;
  }
  case UPDATE_DN:
  case UPDATE_YKUE_ID:
  case UPDATE_TEMPLATE_LIST:
  case UPDATE_TEMPLATE:
  case UPDATE_ISSUED_CERTIFICATE:
  case UPDATE_ISSUED_CERTIFICATE_LIST:
  case UPDATE_CERTIFICATE_VALUE:
    return { ...state,
      [action.key]: action.value };
  case RESET_CERTIFICATE_ISSUING_STATE:
    return defaultState;
  default:
    return state;
  }

};

export default Certificate;
