import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundPosition: "center",
    backgroundSize: "95%",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    backgroundColor: "gray"
  },
  paperContainer: {
    opacity: 0.5,
    backgroundPosition: "center",

    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
  },
  fullSize: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: "100%",
      maxHeigth: "100%",
    },
  },
}));
