import {
  UPDATE_FIELD_USERS,
  USER_CREATE_LOAD,
  USER_CREATE_SUCCESSFUL,
  USER_CREATE_UNSUCCESSFUL,
} from "../features/common/components/certificate_list/action_type";
import {
  CERTIFICATE_OPERATOR,
  ROLE_ADMINISTRATOR,
} from "../features/common/constants/dev_artifacts";

const defaultState = {
  cpfCnpj: "",
  certificatePem: CERTIFICATE_OPERATOR,
  userRole: ROLE_ADMINISTRATOR,
  users: [],
};

const Users = (state = defaultState, action) => {
  switch (action.type) {
  case UPDATE_FIELD_USERS:
    return { ...state, [action.key]: action.value };
  case USER_CREATE_LOAD:
    return {
      certificatePem: CERTIFICATE_OPERATOR,
      userRole: ROLE_ADMINISTRATOR,
    };
  case USER_CREATE_SUCCESSFUL:
    return defaultState;
  case USER_CREATE_UNSUCCESSFUL:
    return defaultState;
  default:
    return state;
  }
};

export default Users;
