import { combineReducers } from "redux";
import home from "./reducers/home";
import common from "./reducers/common";
import auth from "./reducers/auth";
import users from "./reducers/users";
import template from "./reducers/template";
import template_list from "./reducers/template_list";
import template_auth from "./reducers/template_auth";
import sign from "./reducers/sign";
import crl from "./reducers/crl";
import certificate from "./reducers/certificate";
import { connectRouter } from "connected-react-router";
import developerMode from "./reducers/developer_mode";

const createRootReducer = (history) =>
  combineReducers({
    auth,
    common,
    home,
    users,
    template,
    template_list,
    template_auth,
    sign,
    crl,
    certificate,
    developerMode,
    router: connectRouter(history),
  });

export default createRootReducer;
