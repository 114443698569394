import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import {
  handleCancelClick,
  handleDeleteClick,
  handleEditClick, handleRowEditStop,
  handleRowModesModelChange,
  handleSaveClick, processRowUpdate
} from "../../../common/functions/list_functions";
import PropTypes from "prop-types";

export const EditToolbar = ({setRows, setRowModesModel}) => {
  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, description: '', oid: '', modifiable: false, value:'' }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
				Add Attribute
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRows: PropTypes.func.isRequired,
  setRowModesModel: PropTypes.func.isRequired
}

export const AttributeList = ({rows, setRows}) => {
  const [rowModesModel, setRowModesModel] = React.useState({});

  const getActions = (id) => {
    id = id.id;
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

    if (isInEditMode) {
      return [
        <GridActionsCellItem
          key="save"
          icon={<SaveIcon />}
          label="Save"
          sx={{
            color: 'primary.main',
          }}
          onClick={handleSaveClick(id, rowModesModel, setRowModesModel)}
        />,
        <GridActionsCellItem
          key="cancel"
          icon={<CancelIcon />}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(id, rows, setRows, rowModesModel, setRowModesModel)}
          color="inherit"
        />,
      ];
    }

    return [
      <GridActionsCellItem
        key="edit"
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(id, rowModesModel, setRowModesModel)}
        color="inherit"
      />,
      <GridActionsCellItem
        key="delete"
        icon={<DeleteIcon />}
        label="Delete"
        onClick={handleDeleteClick(id, rows, setRows)}
        color="inherit"
      />,
    ];
  }

  const columns = [
    {
      field: 'oid',
      headerName: 'Object Identifier',
      flex: 0.75,
      editable: true
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'modifiable',
      headerName: 'Field Type',
      flex: 0.8,
      type: 'singleSelect',
      editable: true,
      valueOptions: [
        { value: true, label: 'Modifiable' },
        { value: false, label: 'Not Modifiable' }
      ],
      headerAlign: 'left'
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1.2,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.4,
      cellClassName: 'actions',
      getActions: getActions,
    },
  ];

  return (
    <Box
      sx={{
        height: 410,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        pageSizeOptions={[5]}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(e) => handleRowModesModelChange(e, setRowModesModel)}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={(newRow) => processRowUpdate(newRow, rows, setRows)}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}

AttributeList.propTypes = {
  rows: PropTypes.array.isRequired,
  setRows: PropTypes.func.isRequired
}