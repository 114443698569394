import React, {useEffect, useState} from 'react';
import Agent from "../../communication/agent"
import PropTypes from "prop-types";
import RevokeForm from "../../../revoke/components/revoke_form";
import CertificateEnhancedTable from "./certificate_table";
import {CertificateFilter} from "./certificate_filter";
import {YbyraContainer} from "../../style";

const CertificateListLoaderComponent = ({ type }) => {
  const [certlist, setCertlist] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([]);
  const [totalElements, setTotalElements] = React.useState(0);

  const [commonNameFilter, setCommonNameFilter] = useState("");
  const [serialNumberFilter, setSerialNumberFilter] = useState("");
  const [validFilter, setValidFilter] = useState(false);

  const [message, setMessage] = useState('');
  const [code, setCode] = useState(0);


  const doRequest = async () => {
    var params = new Object();
    params.page = currentPage;
    params.size = rowsPerPage;
    params.valid = validFilter;
    if (commonNameFilter !== "") params.commonName = commonNameFilter;
    if (serialNumberFilter !== "") params.serialNumber = serialNumberFilter;
    var response = await Agent.Certificate.list(params);

    setCertlist(response.content);
    setTotalElements(response.totalElements);
    limitMaxPageSize(response.totalElements);
  }

  // This function uses total as a parameter because the state totalElements updates asynchronously, we directly
  // use the response result
  function limitMaxPageSize(total) {
    const res = [5];
    for(let i = 10; i <= Math.min(Math.ceil(total / 5) * 5, 15); i += 5) {
      res.push(i);
    }
    setRowsPerPageOptions(res)
  }

  const PAGE_TITLE = {
    download: <h2>Certificate List</h2>,
    revoke: <h2>Revoke Certificate</h2>,
  }

  const EXTRA_ELEMENTS = {
    download: null,
    revoke: <RevokeForm setCode={setCode} setMessage={setMessage}></RevokeForm>,
  }

  useEffect(() => {doRequest();}, [rowsPerPage, currentPage, commonNameFilter, serialNumberFilter, validFilter]);

  useEffect(() => {setMessage(''); setCode(0)}, [type]);

  return (
    <YbyraContainer>
      <br/>
      {PAGE_TITLE[type]}
      {EXTRA_ELEMENTS[type]}
      <CertificateFilter validFilter={validFilter}
        setValidFilter={setValidFilter}
        commonNameFilter={commonNameFilter}
        setCommonNameFilter={setCommonNameFilter}
        serialNumberFilter={serialNumberFilter}
        setSerialNumberFilter={setSerialNumberFilter}
      />
      <CertificateEnhancedTable certificates={certlist}
        type={type}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={currentPage}
        setPage={setCurrentPage}
        totalElements={totalElements}
        rowsPerPageOptions={rowsPerPageOptions}
        code={code}
        message={message}/>
    </YbyraContainer>
  );
}

CertificateListLoaderComponent.propTypes = {
  type : PropTypes.string.isRequired,
}

export default CertificateListLoaderComponent;
