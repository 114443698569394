import React from "react";
import {Route} from "react-router-dom";
import AuthenticationComponent from "./authentication_form";

const loginRoutes = (
  <React.Fragment>
    <Route path="login" element={<AuthenticationComponent />} />
  </React.Fragment>
)

export default loginRoutes;