import React, { useContext } from "react";
import Agent from "../../common/communication/agent";
import { PropTypes } from "prop-types";
import {
  AlertDataContext,
  setErrorAlert,
  setSuccessAlert,
  setWarningAlert,
} from "../../common/components/alerter";
import {
  REVOCATION_ERROR_GENERAL,
  REVOCATION_SUCCESS,
  REVOCATION_WARNING,
} from "../../common/constants/errors";

const RevokeCertificate = (props) => {
  const alertContext = useContext(AlertDataContext);
  const { setCertErrors, serialNumbers, code, reason } = props;

  function checkResponse(response) {
    const { errorSize: errorsFound, notRevoked } = response.meta;
    const certificates = response.response;

    const notRevokedCertificates = certificates.reduce((certificates, certificate) => {
      const { serialNumber, message } = certificate;
      if (notRevoked.includes(serialNumber)) {
        certificates[serialNumber] = message;
      }
      return certificates;
    }, {});

    if (errorsFound === certificates.length) {
      setErrorAlert(REVOCATION_ERROR_GENERAL, alertContext);
    } else if (errorsFound > 0) {
      setWarningAlert(REVOCATION_WARNING, alertContext);
    } else {
      setSuccessAlert(REVOCATION_SUCCESS, alertContext);
    }

    setCertErrors(notRevokedCertificates);
  }

  async function handleClick() {
    var request = {};
    request.serialNumbers = serialNumbers;
    request.reasonCode = code;
    request.reason = reason;
    var json = JSON.stringify(request);
    try {
      var response = await Agent.Revoke.revoke(json);
      checkResponse(response);
    } catch (error) {
      setErrorAlert(REVOCATION_ERROR_GENERAL, alertContext);
    }
  }

  return (
    <button className="btn btn-danger" onClick={handleClick} disabled={reason.length == 0}>
      Revoke
    </button>
  );
};

RevokeCertificate.propTypes = {
  serialNumbers: PropTypes.array.isRequired,
  code: PropTypes.number.isRequired,
  setCertErrors: PropTypes.func.isRequired,
  reason: PropTypes.string.isRequired,
};

export default RevokeCertificate;
