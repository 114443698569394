import {Button} from "@material-ui/core";
import React from "react";
import {mainStyle} from "../../../styles/base-css";
import Typography from "@mui/material/Typography";
import {env} from "../../../env";


export const YkueDownloadButton = () => {
  const classes = mainStyle()
  return <Button
    variant="contained"
    className={classes.button}
    href={env.REACT_APP_YKUE_DOWNLOAD_URL} // Link to Ykue download
    target="_blank"
  >
    Download Ykue&nbsp;
    <Typography variant="caption" className={classes.badge}>
      {env.REACT_APP_YKUE_VERSION}
    </Typography>

  </Button>
}
